import type { SidebarLocationControlOption } from '@meterup/metric';
import { SidebarLocationControl } from '@meterup/metric';
import React from 'react';
import { Item } from 'react-stately';

import {
  useChangeCurrentControllerCallback,
  useCurrentController,
} from '../../providers/CurrentControllerProvider';
import { useControllerList } from './useControllerList';

interface LocationControlProps {
  onClose: () => void;
}

export const LocationControl = ({ onClose }: LocationControlProps) => {
  const currentController = useCurrentController();
  const controllers = useControllerList();

  const dropDownOptions: SidebarLocationControlOption[] = controllers
    .map((c) => ({
      value: c.name,
      label: c.address,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const changeCurrentController = useChangeCurrentControllerCallback();

  const handleControllerSelect = (slug: string) => {
    changeCurrentController(slug);
    onClose();
  };

  return (
    <SidebarLocationControl
      aria-label="Choose a location"
      value={currentController}
      onValueChange={(key) => handleControllerSelect(key as string)}
    >
      {dropDownOptions.map((item) => (
        <Item key={item.value}>{item.label}</Item>
      ))}
    </SidebarLocationControl>
  );
};
