import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { getSuggestedPassword } from '../../../../api/api';

export const useSuggestPasswordState = (onSuggestPassword: (pwd: string) => void) => {
  const [wordCount, setWordCount] = useState(4);
  const isMounted = useRef(false);

  const refreshAndUpdatePassword = useCallback(async () => {
    const pwd = await getSuggestedPassword(wordCount);
    onSuggestPassword(pwd ?? '');
  }, [onSuggestPassword, wordCount]);

  const refreshPassword = useDebouncedCallback(refreshAndUpdatePassword, 20, { leading: true });

  useEffect(() => {
    if (isMounted.current) {
      refreshPassword();
    }

    isMounted.current = true;

    // We only want to react to changes to wordCount, so deliberately excluding shouldSuggestPassword
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPassword, wordCount]);

  return { wordCount, setWordCount, refreshPassword };
};
