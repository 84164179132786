import { isDefined } from '@meterup/common';
import {
  Alert,
  Body2,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  List,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
} from '@meterup/metric';
import React, { Suspense, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { deleteToken, getToken, getUser } from '../../api/api';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { routes } from '../../routes';
import { CloseDrawerButton } from '../CloseDrawerButton';
import { LoadingDrawer } from './LoadingDrawer';

const DRAWER_TITLE = 'Client token';

const TokenDetailFooter = ({ userSid, tokenSid }: { userSid: string; tokenSid: string }) => {
  const closeDrawer = useCloseDrawerCallback();
  const client = useQueryClient();

  const [isConfirmingDelete, setIsConfirmingDelete] = useState<boolean>(false);

  const deleteTokenMutation = useMutation(() => deleteToken(userSid, tokenSid), {
    onSuccess: () => {
      client.invalidateQueries(['company_users']);
      client.invalidateQueries(['company_token_and_users']);
      closeDrawer();
    },
  });

  return (
    <DrawerFooter>
      {isConfirmingDelete ? (
        <>
          <Body2>Are you sure?</Body2>
          <DrawerControls>
            <Button key="cancel" variant="secondary" onClick={() => setIsConfirmingDelete(false)}>
              Cancel
            </Button>
            <Button
              key="confirmDelete"
              variant="destructive"
              onClick={() => deleteTokenMutation.mutate()}
            >
              Delete
            </Button>
          </DrawerControls>
        </>
      ) : (
        <DrawerControls>
          <Button
            key="startConfirmingDelete"
            variant="destructive"
            onClick={() => setIsConfirmingDelete(true)}
          >
            Delete
          </Button>
        </DrawerControls>
      )}
    </DrawerFooter>
  );
};

const TokenDetails = () => {
  const companySlug = useCurrentCompany();
  const params = Nav.useRegionParams('drawer', routes.drawers.clientTokens.detail.path)!;

  const { data: tokenData } = useQuery(
    ['user_token', params.userSid, params.tokenSid],
    () => getToken(params.userSid, params.tokenSid),
    { suspense: true },
  );

  const { data: userData } = useQuery(
    ['user', params.userSid],
    () => getUser(companySlug, params.userSid),
    {
      suspense: true,
    },
  );

  return isDefined(tokenData) && isDefined(userData) ? (
    <>
      <DrawerContent>
        <List>
          <ListItemPair>
            <ListItemLabel>Email</ListItemLabel>
            <ListItemValue>
              <Body2>{userData.email}</Body2>
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Alias</ListItemLabel>
            <ListItemValue>
              <Body2>{tokenData.alias}</Body2>
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Value</ListItemLabel>
            <ListItemValue>
              <Body2>{tokenData.user_token}</Body2>
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Available at</ListItemLabel>
            <ListItemValue>
              <Body2>{tokenData.available_at}</Body2>
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Expires at</ListItemLabel>
            <ListItemValue>
              <Body2>{tokenData.expires_at}</Body2>
            </ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Max devices limit</ListItemLabel>
            <ListItemValue>
              <Body2>{tokenData.max_clients_limit}</Body2>
            </ListItemValue>
          </ListItemPair>
        </List>
      </DrawerContent>
      <TokenDetailFooter tokenSid={params.tokenSid} userSid={params.userSid} />
    </>
  ) : (
    <DrawerContent>
      <Alert copy="The requested token was not found." />
    </DrawerContent>
  );
};

export const TokenDetailDrawer = () => (
  <Suspense fallback={<LoadingDrawer title={DRAWER_TITLE} />}>
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>{DRAWER_TITLE}</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <TokenDetails />
    </Drawer>
  </Suspense>
);
