import { ProgressBar } from '@meterup/metric';
import cx from 'classnames';
import React from 'react';

export const DiscreteProgressBar: React.FC<{
  value: number;
  maxValue: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}> = ({ value, maxValue }) => {
  const classes = cx('d-grid', 'd-gap-2', {
    'd-grid-cols-1': maxValue === 1,
    'd-grid-cols-2': maxValue === 2,
    'd-grid-cols-3': maxValue === 3,
    'd-grid-cols-4': maxValue === 4,
    'd-grid-cols-5': maxValue === 5,
    'd-grid-cols-6': maxValue === 6,
    'd-grid-cols-7': maxValue === 7,
    'd-grid-cols-8': maxValue === 8,
    'd-grid-cols-9': maxValue === 9,
    'd-grid-cols-10': maxValue === 10,
    'd-grid-cols-11': maxValue === 11,
    'd-grid-cols-12': maxValue === 12,
  });

  return (
    <div className={classes}>
      {new Array(maxValue).fill(0).map((d, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ProgressBar key={i} progressPercentage={value >= i + 1 ? 100 : 0} />
      ))}
    </div>
  );
};
