import { Body } from '@meterup/metric';
import React from 'react';

export const FieldLabel: React.FC<{ htmlFor?: string; children: React.ReactNode }> = ({
  htmlFor,
  children,
}) => (
  <Body as="label" htmlFor={htmlFor} weight="medium" color={{ light: 'gray-700', dark: 'gray-50' }}>
    {children}
  </Body>
);
