import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { routes } from '../../routes';
import { LocationDetail } from './LocationDetail';
import { LocationList } from './LocationList';
import { OnboardingFormContainer } from './OnboardingForm/OnboardingFormContainer';

export const NetworkSetup = () => (
  <Router>
    <Routes>
      <Route path={routes.setup.root.path} element={<LocationList />} />
      <Route path={routes.setup.location.root.path} element={<LocationDetail />} />
      <Route path={routes.setup.location.form.root.path} element={<OnboardingFormContainer />} />
    </Routes>
  </Router>
);
