import type { ManufacturerIconName } from '@meterup/metric';
import { isAfter, isValid } from 'date-fns';
import oui from 'oui';
import { match } from 'ts-pattern';

import type { ClientData } from '../api/types';

export type ConnectionStatus = 'online' | 'offline' | 'wired';

export interface FormattedClientData extends ClientData {
  connection_status: ConnectionStatus;
}

export const isWired = (c: ClientData) => Number(c.signal) === 0;

export const isWireless = (c: ClientData) => Number(c.signal) !== 0;

export const isGuest = (c: ClientData) => c.tags.includes('guest');

export const clientNameOrNull = (c: ClientData) => (c.name !== '*' ? c.name : null);

export const isLastSeenKnown = (c: ClientData) => {
  if (c.last_seen) {
    const date = new Date(c.last_seen);
    return isValid(date) && isAfter(date, new Date(2010, 1, 1));
  }

  return false;
};

export const isOnline = (c: ClientData): boolean => {
  if (c.last_seen) {
    const dateLessFive = new Date();
    const itemDate = new Date(c.last_seen);

    dateLessFive.setMinutes(dateLessFive.getMinutes() - 5);

    return itemDate >= dateLessFive;
  }

  return false;
};

export const getClientConnectionStatus = (client: ClientData): ConnectionStatus => {
  if (isWireless(client)) {
    return isOnline(client) ? 'online' : 'offline';
  }

  return 'wired';
};

export const manufacturerIconName = (client: ClientData): ManufacturerIconName =>
  match(oui(client.mac_address)?.toLowerCase() ?? '')
    .when(
      (v) => v.includes('apple'),
      () => 'apple' as const,
    )
    .when(
      (v) => v.includes('samsung'),
      () => 'samsung' as const,
    )
    .when(
      (v) => v.includes('chrome'),
      () => 'chrome' as const,
    )
    .when(
      (v) => v.includes('ecobee'),
      () => 'ecobee' as const,
    )
    .when(
      (v) => v.includes('samsung'),
      () => 'samsung' as const,
    )
    .when(
      (v) => v.includes('sonos'),
      () => 'sonos' as const,
    )
    .otherwise(() => 'unknown' as const);

export const formatClientData = (client: ClientData): FormattedClientData => ({
  ...client,
  connection_status: getClientConnectionStatus(client),
});

export enum FilterStrategy {
  All = 'all',
  Wired = 'wired',
  Wireless = 'wireless',
  Guest = 'guest',
}

export const getActiveFilter = (
  strategy: FilterStrategy,
  // eslint-disable-next-line consistent-return
): ((c: ClientData) => boolean) => {
  // eslint-disable-next-line default-case
  switch (strategy) {
    case FilterStrategy.All:
      return () => true;
    case FilterStrategy.Wired:
      return isWired;
    case FilterStrategy.Wireless:
      return isWireless;
    case FilterStrategy.Guest:
      return isGuest;
  }
};
