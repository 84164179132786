/* eslint-disable react/prop-types */
import type { Column, UseGlobalFiltersInstanceProps, UseGlobalFiltersState } from 'react-table';
import { isDefined } from '@meterup/common';
import { activeThemeClassName, Alert, Icon, space, TextInput } from '@meterup/metric';
import classNames from 'classnames';
import React from 'react';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';

import type { NavProps } from '../../nav';
import { useCanScrollX } from '../../hooks/useCanScrollX';
import { Nav } from '../../nav';
import {
  colors,
  css,
  focusVisibleSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches';

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginTop: -0.5,
  marginBottom: -0.5,
});

const TableBar = css({
  hStack: '$16',
  width: '100%',
});

export const TableTopBar = styled('div', TableBar, {
  padding: '$12 14px',
  borderRadiusTop: '$8',
});

export const TableTopBarTabs = styled('div', {});
const TableTopBarControls = styled('div', { marginLeft: 'auto' });

const TableContainer = styled('div', {
  overflow: 'auto',
  WebkitOverflowScrolling: 'auto',
});

const TableBase = styled('div', {
  width: '100%',
  position: 'relative',
  display: 'table',
  borderCollapse: 'collapse',
});

const TableHead = styled('div', {
  display: 'table-header-group',
});

const TableBody = styled('div', {
  display: 'table-row-group',
});

/**
 * TRICKY: The TableCell makes use of the :before and :after pseudo-elements.
 *
 *   :before => The row's focus ring. Each cell renders a part of the focus ring
 *   via a box-shadow, but the shadow is clipped horizontally by the cell's
 *   overflow: hidden property. This gives the illusion that the focus ring
 *   expands and contracts as the user scrolls horizontally.
 *
 *   :after => The first column's scroll shadow, visible when the table's
 *   content overflows.
 */
const TableCell = css({
  $$cellFocusRingInsetRight: 0,
  $$cellFocusRingInsetLeft: 0,

  display: 'table-cell',
  // Y-axis padding would be 10px, but the rows have a border, so we take 1px
  // off the top and bottom.
  padding: '9px $8',
  whiteSpace: 'nowrap',
  position: 'relative',
  zIndex: 0,
  overflow: 'hidden',
  color: '$$rowTextColor',
  verticalAlign: 'middle',

  '&:before': {
    content: '',
    position: 'absolute',
    inset: '8px $$cellFocusRingInsetRight 8px $$cellFocusRingInsetLeft',
    pointerEvents: 'none',
  },

  '&:first-child': {
    paddingLeft: '$20',
    paddingRight: '$16',
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'transparent',
    fontWeight: fontWeights.medium,
    color: '$$rowHeadingTextColor',
    left: 0,
    '&:before': {
      $$cellFocusRingInsetLeft: '12px',
      borderRadiusLeft: '$$rowFocusRingBorderRadius',
    },
    '&:after': {
      content: '',
      position: 'absolute',
      inset: '-1px 8px -1px 0',
      zIndex: -1,
      backgroundColor: '$$rowBackgroundColor',
      pointerEvents: 'none',
      transition: 'box-shadow 150ms ease-out',
    },
  },
  '&:last-child': {
    paddingRight: '$20',
    '&:before': {
      $$cellFocusRingInsetRight: '12px',
      borderRadiusRight: '$$rowFocusRingBorderRadius',
    },
  },

  variants: {
    canScroll: {
      true: {
        '&:first-child:after': {
          boxShadow: '$$leadingColumnScrollShadow',
        },
      },
    },
  },
});

const TableRow = styled('div', {
  $$rowBackgroundColor: colors.white,
  $$rowIconColor: colors['gray-400'],
  $$rowTextColor: colors['gray-600'],
  $$rowHeadingTextColor: colors['gray-700'],
  $$rowFocusRingShadow: '0 0 0 1px #474d81, 0 0 0 4px #8790da',
  $$rowFocusRingBorderRadius: '8px',
  $$rowBorderColor: colors['fence-light'],
  $$leadingColumnScrollShadow:
    '1px 0px 2px rgba(41, 42, 51, 0.02), 3px 0px 5px rgba(41, 42, 51, 0.04)',

  position: 'relative',
  display: 'table-row',
  width: '100%',
  outline: 'none',

  borderTop: `1px solid $$rowBorderColor`,
  '&:last-child': {
    borderBottom: `1px solid $$rowBorderColor`,
  },

  // TRICKY: We want to set a background color on the row. It is typically
  // recommend to set a background color on each table cell. However, due to the
  // delicate z-index-ing with how we render a shadow and a focus ring on the
  // table cells, it's not possible to push the background to the table cells.
  // Chrome and Safari will render a row's background color just fine. However,
  // it's a known bug that Firefox will paint the row's background color over
  // the row's border. For Firefox, we use a pseudo-element to render the row
  // background color.
  //
  // You might think we could keep things simple and use the pseudo-element for
  // all three browsers. But no! Safari and Chrome do not have the background
  // color bug; however, Safari's behavior differs from Chrome's when a
  // table-row has position: relative. In Safari, the row does not create a
  // stacking context, so the pseudo-element is not positioned relative to the
  // row's content box. Instead, it is positioned relative to the table. Welcome
  // back to the browser wars.
  //
  // @see https://bugzilla.mozilla.org/show_bug.cgi?id=688556
  // @see https://developer.mozilla.org/en-US/docs/Web/CSS/position#values
  // @see https://en.wikipedia.org/wiki/Browser_wars
  backgroundColor: '$$rowBackgroundColor',
  '@-moz-document url-prefix()': {
    backgroundColor: 'transparent',
    '&:before': {
      content: '',
      position: 'absolute',
      inset: 0,
      backgroundColor: '$$rowBackgroundColor',
      pointerEvents: 'none',
      // Set z-index so that the pseudo-element doesn't paint over the borders
      zIndex: -1,
    },
  },

  [`& .${TableCell}:before`]: {
    transition: 'box-shadow 150ms ease-out',
    boxShadow: shadows.none,
  },
  [focusVisibleSelector]: {
    '&:focus': {
      [`& .${TableCell}:before`]: {
        boxShadow: '$$rowFocusRingShadow',
      },
    },
  },
  variants: {
    isSelected: {
      true: {
        $$rowBackgroundColor: colors['brand-600'],
        $$rowTextColor: colors['brand-50'],
        $$rowHeadingTextColor: colors['brand-50'],
        $$rowIconColor: colors['brand-50'],
        $$rowFocusRingShadow: '0px 0px 0px 1px #dfe3f9, 0px 0px 0px 4px #a2a9e3',
      },
      false: {},
    },
  },
});

const TableHeadRow = styled(TableRow, {
  $$rowBackgroundColor: colors['gray-50'],
  $$rowTextColor: colors['gray-700'],
});

const TableHeadCell = styled('div', TableCell, {
  paddingTop: '$8',
  // Fix height of heading cell to 32px. Not sure where an extra px is coming from
  paddingBottom: 7,
  fontWeight: fontWeights.medium,
  fontFamily: fonts.sans,
  fontSize: '$12',
  lineHeight: '$16',
});

const TableHeadCellContent = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$4',
});

const TableHeadCellSortIcon = styled(Icon, {
  variants: {
    isVisible: {
      true: {
        // TRICKY: Toggle visibility rather than display to always reserve
        // space in the layout for the icon.
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
  defaultVariants: {
    isVisible: false,
  },
});

const TableDataCell = styled('div', TableCell, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  fontSize: '$14',
  lineHeight: '$20',
});

const StyledIcon = styled(Icon, {
  color: '$$rowIconColor',
  // Nudge icon to center it vertically
  marginTop: -3,
});

interface SelectRowArrowCellProps {
  isNavigableRow: boolean;
}

const SelectRowArrowCell = ({ isNavigableRow }: SelectRowArrowCellProps) => (
  <TableDataCell style={{ width: 0 }} aria-hidden>
    {isNavigableRow && <StyledIcon icon="chevronRight" size={space(12)} />}
  </TableDataCell>
);

const DeselectRowCrossCell = ({ onClick }: { onClick: () => void }) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <TableDataCell onClick={handleClick} style={{ width: 0 }} aria-hidden>
      <StyledIcon icon="crossCircle" size={space(12)} />
    </TableDataCell>
  );
};

const GlobalSearch: React.FC<{
  instanceProps: UseGlobalFiltersInstanceProps<any> & { state: UseGlobalFiltersState<any> };
}> = ({ instanceProps }) => (
  <TextInput
    aria-label="Search table"
    type="search"
    id="search"
    placeholder="Search"
    icon="searchScoped"
    value={instanceProps.state.globalFilter}
    onChange={(value) => {
      instanceProps.setGlobalFilter(value);
    }}
  />
);

export interface TableProps<D extends object = {}> {
  columns: ReadonlyArray<Column<D>>;
  data: readonly D[];
  tabs?: React.ReactNode;
  shouldHideGlobalSearch?: boolean;
  emptyStateHeading?: string;
  emptyStateCopy?: string;
  linkProps?: (row: D) => NavProps | null;
  isRowSelected?: (row: D) => boolean;
  onRowDeselect?: (row: D) => void;
}

export const Table = <D extends object>({
  columns,
  data,
  linkProps,
  isRowSelected,
  onRowDeselect,
  tabs,
  emptyStateHeading = 'No rows',
  shouldHideGlobalSearch = false,
  emptyStateCopy,
}: TableProps<D>) => {
  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const [ref, canScroll] = useCanScrollX();

  const isNavigableTable = isDefined(linkProps);

  return (
    <Container>
      {(tabs || !shouldHideGlobalSearch) && (
        <TableTopBar>
          {tabs && <TableTopBarTabs>{tabs}</TableTopBarTabs>}
          {!shouldHideGlobalSearch && (
            <TableTopBarControls>
              <GlobalSearch instanceProps={tableInstance} />
            </TableTopBarControls>
          )}
        </TableTopBar>
      )}
      <TableContainer>
        <TableBase {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeadCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    canScroll={canScroll}
                  >
                    <TableHeadCellContent>
                      {column.render('Header')}
                      <TableHeadCellSortIcon
                        isVisible={column.isSorted}
                        icon={column.isSortedDesc ? 'chevronDown' : 'chevronUp'}
                        size={space(10)}
                      />
                    </TableHeadCellContent>
                  </TableHeadCell>
                ))}
                {isNavigableTable && <TableHeadCell aria-hidden />}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody ref={ref} {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const props = linkProps?.(row.original);
              const RowComponent = isDefined(props) ? Nav.Link : 'div';
              const isNavigableRow = isDefined(props) && isNavigableTable;
              const rowIsSelected = isRowSelected?.(row.original) ?? false;

              return (
                <TableRow
                  {...row.getRowProps()}
                  {...props}
                  as={RowComponent}
                  isSelected={rowIsSelected}
                  className={classNames({
                    [activeThemeClassName]: rowIsSelected,
                  })}
                >
                  {row.cells.map((cell) => (
                    <TableDataCell {...cell.getCellProps()} canScroll={canScroll}>
                      {cell.render('Cell')}
                    </TableDataCell>
                  ))}
                  {rowIsSelected && onRowDeselect ? (
                    <DeselectRowCrossCell onClick={() => onRowDeselect(row.original)} />
                  ) : (
                    <SelectRowArrowCell isNavigableRow={isNavigableRow} />
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </TableBase>
      </TableContainer>
      {rows.length === 0 && (
        <Alert heading={emptyStateHeading} copy={emptyStateCopy} cornerStyle="square" />
      )}
    </Container>
  );
};
