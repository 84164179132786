import type { api } from '@meterup/proto';
import {
  ConnectionStatusBadge,
  DeviceStatusBadge,
  EnabledStatusBadge,
  isDefined,
  OnlineOfflineStatusBadge,
  UnknownBadge,
  UserRoleBadge,
} from '@meterup/common';
import { BodyMono2 } from '@meterup/metric';
import { isAfter, isValid } from 'date-fns';
import React from 'react';

import type {
  DeviceStatus,
  EnabledDisabledUnknownStatus,
  OnlineOfflineUnknownStatus,
} from '../../api/types';
import type { ConnectionStatus } from '../../utils/clientLists';
import formatTime from '../../utils/formatTime';

export const CellOnlineOfflineStatus = ({ value }: { value: OnlineOfflineUnknownStatus }) => (
  <OnlineOfflineStatusBadge value={value} />
);

export const CellDeviceStatus = ({ value }: { value: DeviceStatus }) => (
  <DeviceStatusBadge status={value} />
);

export const CellConnectionStatus = ({ value }: { value: ConnectionStatus }) => (
  <ConnectionStatusBadge status={value} />
);

export const CellEnabledDisabledStatus = ({ value }: { value: EnabledDisabledUnknownStatus }) => (
  <EnabledStatusBadge status={value} />
);

export const CellIPAddress = ({ value }: { value: string }) => <BodyMono2>{value}</BodyMono2>;

export const CellMACAddress = ({ value }: { value: string }) => <BodyMono2>{value}</BodyMono2>;

export const CellRelativeTimestamp = ({ value }: { value: string }) => formatTime(new Date(value));

export const CellClientLastSeenTime = ({ value }: { value?: string }) => {
  // NOTE: Some timestamps have defaulted to years like 1970 or 0001. We're papering over them for now.
  if (isDefined(value)) {
    const date = new Date(value);
    if (isValid(date) && isAfter(date, new Date(2010, 1, 1))) {
      return <CellRelativeTimestamp value={value} />;
    }
  }

  return <UnknownBadge />;
};

export const CellUserRole = ({ value }: { value: api.CompanyMembershipRole }) => (
  <UserRoleBadge value={value} />
);
