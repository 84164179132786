import {
  Button,
  Heading,
  HStack,
  List,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/metric';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import type { OnboardingFormData } from '../types';
import { FieldProvider } from '../../../FieldProvider';
import { PasswordStrengthIndicator } from '../../../PasswordStrengthIndicator';
import { BackButton } from '../components/BackButton';
import { NextStepButton } from '../components/NextStepButton';
import { WordCountListItem } from '../components/WordCountListItem';
import { useSuggestPasswordState } from '../hooks/useSuggestPasswordState';

export const NetworkSetupStep: React.FC = () => {
  const form = useFormikContext<OnboardingFormData>();

  const onSetPassword = useCallback(
    (pwd: string) => {
      form.setFieldValue('mainNetworkData.password', pwd);
    },
    [form],
  );

  const { wordCount, setWordCount, refreshPassword } = useSuggestPasswordState(onSetPassword);

  return (
    <VStack spacing={space(16)}>
      <Heading>Network setup</Heading>
      <List>
        <FieldProvider name="mainNetworkData.ssid">
          <PrimaryField
            label="Corporate SSID"
            element={<TextInput autoComplete="off" autoFocus />}
          />
        </FieldProvider>
      </List>
      <List>
        <FieldProvider name="mainNetworkData.password">
          <PrimaryField
            label="Password"
            element={<TextInput autoComplete="off" />}
            controls={
              <>
                <PasswordStrengthIndicator password={form.values.mainNetworkData.password ?? ''} />
                <Button
                  size="small"
                  icon="arrowRotate"
                  type="button"
                  arrangement="hidden-label"
                  variant="secondary"
                  onClick={refreshPassword}
                >
                  Suggest a password
                </Button>
              </>
            }
          />
        </FieldProvider>
        <WordCountListItem value={wordCount} onChange={setWordCount} />
      </List>
      <HStack justify="between">
        <BackButton />
        <NextStepButton requireValidationAtPath="mainNetworkData" />
      </HStack>
    </VStack>
  );
};
