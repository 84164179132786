import {
  MobileSidebar,
  MobileSidebarLocationControlContainer,
  MobileSidebarToggleContainer,
  SidebarGroup,
  SidebarNavItem,
} from '@meterup/metric';
import React from 'react';

import { LocationControl } from './LocationControl';
import { MobileSidebarModal } from './MobileSidebarModal';
import { ScopedMobileSidebarEntries, ScopedMobileSidebarToggle } from './ScopedMobileSidebar';
import { networkSidebarEntries } from './sidebarEntries';

interface NetworkSidebarMobileProps {
  onClose: () => void;
}

const NetworkSidebarMobile: React.FC<NetworkSidebarMobileProps> = ({ onClose }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <ScopedMobileSidebarToggle entries={networkSidebarEntries} onClick={onClose} isOpen />
      </MobileSidebarToggleContainer>

      <ScopedMobileSidebarEntries entries={networkSidebarEntries} onClose={onClose} />

      <SidebarGroup>
        <SidebarNavItem as="button" label="Log out" />
      </SidebarGroup>

      <MobileSidebarLocationControlContainer>
        <LocationControl onClose={onClose} />
      </MobileSidebarLocationControlContainer>
    </MobileSidebar>
  </MobileSidebarModal>
);

export default NetworkSidebarMobile;
