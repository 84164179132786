import {
  BasicSelect,
  BasicSelectItem,
  Box,
  Button,
  CompositeField,
  Heading,
  HStack,
  List,
  PrimaryFieldComposite,
  SecondaryField,
  Small,
  space,
  TextInput,
  VStack,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { useFormikContext } from 'formik';
import React from 'react';
import Input from 'react-phone-number-input/input';

import type { OnboardingFormData } from '../types';
import { getInputProps } from '../../../../utils/inputs';
import { FieldProvider } from '../../../FieldProvider';
import { BackButton } from '../components/BackButton';

export const MeterInstallStep: React.FC = () => {
  const form = useFormikContext<OnboardingFormData>();
  const isContactSelf = form.values.buildingData.contactType === api.BuildingContactType.self;
  return (
    <VStack spacing={space(16)}>
      <Heading>Meter install</Heading>
      <List>
        <PrimaryFieldComposite
          label="Who do we contact to access your building?"
          fields={
            <HStack align="center" justify="between">
              <Small weight="medium">Contact type</Small>
              <Box width="fit-content">
                <FieldProvider name="buildingData.contactType">
                  <CompositeField
                    label="Building contact type"
                    element={
                      <BasicSelect controlSize="small">
                        <BasicSelectItem value={api.BuildingContactType.property_manager}>
                          Property Manager
                        </BasicSelectItem>
                        <BasicSelectItem value={api.BuildingContactType.riser_manager}>
                          Riser Manager
                        </BasicSelectItem>
                        <BasicSelectItem value={api.BuildingContactType.self}>Self</BasicSelectItem>
                        <BasicSelectItem value={api.BuildingContactType.other}>
                          Other
                        </BasicSelectItem>
                      </BasicSelect>
                    }
                  />
                </FieldProvider>
              </Box>
            </HStack>
          }
        />
        <FieldProvider name="buildingData.tel">
          <SecondaryField
            label={`${isContactSelf ? 'Your' : 'Their'} phone number`}
            element={
              <Input
                inputComponent={TextInput as any}
                defaultCountry="US"
                {...getInputProps(form, 'buildingData.tel')}
              />
            }
          />
        </FieldProvider>{' '}
        <FieldProvider name="buildingData.email">
          <SecondaryField
            label={`${isContactSelf ? 'Your' : 'Their'} email`}
            element={<TextInput />}
          />
        </FieldProvider>
      </List>
      <HStack justify="between">
        <BackButton />
        <Box width="full">
          <Button width="full" size="large" type="submit">
            Complete
          </Button>
        </Box>
      </HStack>
    </VStack>
  );
};
