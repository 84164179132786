import { CompositeField, SecondaryFieldComposite, TextInput } from '@meterup/metric';
import { clamp } from 'lodash';
import React from 'react';

const MIN_WORD_COUNT = 2;
const MAX_WORD_COUNT = 8;
const snapWordCount = (value: string | number) =>
  clamp(
    typeof value === 'string' ? parseInt(value, 10) || 0 : value,
    MIN_WORD_COUNT,
    MAX_WORD_COUNT,
  );

interface WordCountListItemProps {
  value: number;
  onChange: (value: number) => void;
}

export const WordCountListItem: React.FC<WordCountListItemProps> = ({ value, onChange }) => (
  <SecondaryFieldComposite
    label="Word count"
    fields={
      <div style={{ maxWidth: 40 }}>
        <CompositeField
          label="Word count"
          element={
            <TextInput
              type="number"
              controlSize="small"
              value={value.toString()}
              onChange={(v: string) => onChange(snapWordCount(+v))}
            />
          }
        />
      </div>
    }
  />
);
