import type { Column } from 'react-table';
import { isDefined, isDefinedAndNotEmpty, preloadImage } from '@meterup/common';
import { DeviceIcon, DeviceIconSize } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import type { DeviceData } from '../../api/types';
import { fetchDevices, fetchFloorPlan } from '../../api/api';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useDrawerParams } from '../../hooks/useDrawerParams';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { styled } from '../../stitches';
import { Page, PageHeader, PageSection, PageTitle } from '../Page/Page';
import { PanAndZoomRegion } from '../PanAndZoomRegion/PanAndZoomRegion';
import { Table } from '../Table/Table';
import { CellDeviceStatus } from '../Table/tableCells';

const DeviceName = styled('div', { hStack: '$8' });

const DEVICE_COLUMNS: Column<DeviceData>[] = [
  {
    Header: 'Name',
    accessor: (device) => device.physical_location ?? device.name,
    Cell: ({ value }: { value: string }) => (
      <DeviceName>
        <DeviceIcon size={DeviceIconSize.Small} /> {value}
      </DeviceName>
    ),
  },
  {
    Header: 'Status',
    accessor: (device) => device.status,
    Cell: CellDeviceStatus,
  },
  {
    Header: 'Clients',
    accessor: (device) => device.clients,
  },
];

const Devices = () => {
  const controller = useCurrentController();
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = useDrawerParams(routes.drawers.devices.detail.path);

  const clients = useQuery(['devices', controller], () => fetchDevices(controller), {
    suspense: true,
  });

  const floorPlanURL = useQuery(
    ['floor_plan', controller],
    async () => {
      const url = await fetchFloorPlan(controller);
      return url ? preloadImage(url) : null;
    },
    { suspense: true },
  );

  const allDevices = clients.data ?? [];

  return (
    <Page>
      {isDefined(floorPlanURL.data) && (
        <PageSection>
          <PageHeader>
            <PageTitle>Floor plan</PageTitle>
          </PageHeader>
          <PanAndZoomRegion>
            <img src={floorPlanURL.data} alt="Floor plan" style={{ maxHeight: 300 }} />
          </PanAndZoomRegion>
        </PageSection>
      )}

      <PageSection>
        <PageHeader>
          <PageTitle>Devices</PageTitle>
        </PageHeader>
        <Table
          columns={DEVICE_COLUMNS}
          data={allDevices}
          linkProps={(device) =>
            isDefinedAndNotEmpty(device.name)
              ? {
                  to: {
                    drawer: routes.drawers.devices.detail.pathTo(device.name),
                  },
                }
              : null
          }
          onRowDeselect={closeDrawer}
          isRowSelected={(device) => device.name === drawerParams?.deviceName}
          emptyStateHeading="No devices"
        />
      </PageSection>
    </Page>
  );
};

export default Devices;
