import { isDefined } from '@meterup/common';
import { freeze, immerable } from 'immer';
import { match } from 'ts-pattern';
import { v4 } from 'uuid';

import type { ct } from '../schema/config';

export enum PSKSchemeType {
  None = 'none',
  Static = 'static',
  Rotating = 'rotating',
  MeterAuth = 'meter-auth',
  IEEE8021X = '8021x',
}

export enum PSKRotationFrequency {
  Never = 'never',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface PSKScheme {
  type: PSKSchemeType;
  staticValue: string;
  rotationFrequency: PSKRotationFrequency;
}

const AUTH_PSK2 = 'PSK2';
const AUTH_METER_AUTH = 'Meter Auth';
const AUTH_IEEE8021X = '802.1X';

function pskRotationIntervalFromJSON(
  json: ct.MeterV2WirelessTagServiceSetPSKRotationFrequency,
): PSKRotationFrequency {
  return match(json)
    .with('NEVER', () => PSKRotationFrequency.Never)
    .with('DAILY', () => PSKRotationFrequency.Daily)
    .with('WEEKLY', () => PSKRotationFrequency.Weekly)
    .with('MONTHLY', () => PSKRotationFrequency.Monthly)
    .exhaustive();
}

function pskRotationIntervalToJSON(
  freq: PSKRotationFrequency,
): ct.MeterV2WirelessTagServiceSetPSKRotationFrequency {
  return match(freq)
    .with(PSKRotationFrequency.Never, () => 'NEVER' as const)
    .with(PSKRotationFrequency.Daily, () => 'DAILY' as const)
    .with(PSKRotationFrequency.Weekly, () => 'WEEKLY' as const)
    .with(PSKRotationFrequency.Monthly, () => 'MONTHLY' as const)
    .exhaustive();
}

function getPSKSchemeWithDefaults(json: ct.MeterV2WirelessTagServiceSet): PSKScheme {
  if (json.authentication === undefined) {
    return {
      type: PSKSchemeType.None,
      staticValue: '',
      rotationFrequency: PSKRotationFrequency.Daily,
    };
  }

  if (json.authentication === AUTH_PSK2) {
    let type;
    if (isDefined(json['psk-rotation'])) {
      type = PSKSchemeType.Rotating;
    } else if (isDefined(json.psk)) {
      type = PSKSchemeType.Static;
    } else {
      type = PSKSchemeType.None;
    }

    return {
      type,
      staticValue: json.psk ?? '',
      rotationFrequency: pskRotationIntervalFromJSON(json['psk-rotation']?.frequency ?? 'DAILY'),
    };
  }

  if (json.authentication === AUTH_METER_AUTH) {
    return {
      type: PSKSchemeType.MeterAuth,
      staticValue: '',
      rotationFrequency: PSKRotationFrequency.Daily,
    };
  }

  if (json.authentication === AUTH_IEEE8021X) {
    return {
      type: PSKSchemeType.IEEE8021X,
      staticValue: '',
      rotationFrequency: PSKRotationFrequency.Daily,
    };
  }

  return {
    type: PSKSchemeType.None,
    staticValue: '',
    rotationFrequency: PSKRotationFrequency.Daily,
  };
}

const getStableID = (ssid: string) => btoa(ssid).replaceAll('=', '');

export class MeterV2WirelessServiceSet {
  [immerable] = true;

  private constructor(
    // NOTE: Assumed to be `default` for now, but technically could be something else
    public readonly tagName: string,
    public stableId: string,
    public ssid: string,
    public pskScheme: PSKScheme,
    public bands: Set<ct.MeterV2WirelessTagServiceSetRadioBand>,
    public hidden: boolean,
    public vlan: string,
    public json: ct.MeterV2WirelessTagServiceSet,
  ) {}

  static fromJSON(
    tagName: string,
    ssid: string,
    json: ct.MeterV2WirelessTagServiceSet,
  ): MeterV2WirelessServiceSet {
    const existingId = json.id;
    const stableId = existingId ?? getStableID(ssid);
    const pskScheme = getPSKSchemeWithDefaults(json);
    const bands = json.bands ?? [];
    const hidden = json.hidden ?? false;
    const vlan = json.network ?? '';

    return freeze(
      new MeterV2WirelessServiceSet(
        tagName,
        stableId,
        ssid,
        pskScheme,
        new Set(bands),
        hidden,
        vlan,
        json,
      ),
      true,
    );
  }

  static createEmpty(tagName: string = 'default'): MeterV2WirelessServiceSet {
    return new MeterV2WirelessServiceSet(
      tagName,
      v4(),
      '',
      {
        type: PSKSchemeType.Static,
        staticValue: '',
        rotationFrequency: PSKRotationFrequency.Daily,
      },
      new Set(['5 GHz', '2.4 GHz']),
      false,
      'private',
      {},
    );
  }

  static fromProps(
    tagName: string,
    stableId: string,
    props: {
      ssid: string;
      shouldBroadcastSSID: boolean;
      pskSchemeType: PSKSchemeType;
      pskValue: string;
      pskRotationFrequency: PSKRotationFrequency;
      vlan: string;
      is5GEnabled: boolean;
      is2GEnabled: boolean;
    },
  ): MeterV2WirelessServiceSet {
    const bands = new Set<ct.MeterV2WirelessTagServiceSetRadioBand>();

    if (props.is5GEnabled) {
      bands.add('5 GHz');
    }

    if (props.is2GEnabled) {
      bands.add('2.4 GHz');
    }

    const pskScheme: PSKScheme = {
      type: props.pskSchemeType,
      staticValue: props.pskValue,
      rotationFrequency: props.pskRotationFrequency,
    };

    const hidden = !props.shouldBroadcastSSID;

    return freeze(
      new MeterV2WirelessServiceSet(
        tagName,
        stableId,
        props.ssid,
        pskScheme,
        bands,
        hidden,
        props.vlan,
        {},
      ),
      true,
    );
  }

  getSortedBands() {
    return Array.from(this.bands)
      .sort((a, b) => a.localeCompare(b))
      .reverse();
  }

  getKnownAndAdditionalBands() {
    return {
      '5G': this.bands.has('5 GHz'),
      '2G': this.bands.has('2.4 GHz'),
    };
  }

  setBandStatus(band: ct.MeterV2WirelessTagServiceSetRadioBand, enabled: boolean) {
    if (enabled) {
      this.bands.add(band);
    } else {
      this.bands.delete(band);
    }
  }

  toJSON(): ct.MeterV2WirelessTagServiceSet {
    return {
      ...this.json,
      ...this.pskSchemeToJSON(),
      id: this.stableId,
      bands: Array.from(this.bands),
      hidden: this.hidden ? true : undefined,
      network: this.vlan,
    };
  }

  pskSchemeToJSON() {
    return match(this.pskScheme)
      .with({ type: PSKSchemeType.None }, () => ({
        authentication: undefined,
        psk: undefined,
        'psk-rotation': undefined,
      }))
      .with({ type: PSKSchemeType.Static }, (d) => ({
        authentication: AUTH_PSK2,
        psk: d.staticValue,
        'psk-rotation': undefined,
      }))
      .with({ type: PSKSchemeType.Rotating }, (d) => ({
        authentication: AUTH_PSK2,
        psk: undefined,
        'psk-rotation': {
          frequency: pskRotationIntervalToJSON(d.rotationFrequency),
        },
      }))
      .with({ type: PSKSchemeType.MeterAuth }, () => ({
        authentication: AUTH_METER_AUTH,
        psk: undefined,
        'psk-rotation': undefined,
      }))
      .with({ type: PSKSchemeType.IEEE8021X }, () => ({
        authentication: AUTH_IEEE8021X,
        psk: undefined,
        'psk-rotation': undefined,
      }))
      .otherwise(() => ({}));
  }
}
