import { api } from '@meterup/proto';
import { isEmpty, uniq } from 'lodash';
import * as z from 'zod';

import { pluralize } from '../../../utils/strings';

const validEmail = z.string().email();

const parseCommaOrWhitespaceDelimitedList = (val: string) =>
  val
    .split(/[\s,]+/)
    .map((v) => v.trim())
    .filter((v) => !isEmpty(v));

export const validInviteUsersFormData = z.object({
  emailListRawText: z.string().refine(
    (val) =>
      parseCommaOrWhitespaceDelimitedList(val).every(
        (addressCandidate) => validEmail.safeParse(addressCandidate).success,
      ),
    (val) => {
      const invalidEmails = parseCommaOrWhitespaceDelimitedList(val).filter(
        (v) => !validEmail.safeParse(v).success,
      );
      return {
        message: `${invalidEmails.join(', ')} ${pluralize(
          invalidEmails.length,
          'is not a valid email address',
          'are not valid email addresses',
        )}`,
      };
    },
  ),
  role: z.union([
    z.literal(api.CompanyMembershipRole.guest),
    z.literal(api.CompanyMembershipRole.member),
    z.literal(api.CompanyMembershipRole.admin),
  ]),
});

export const extractUniqueEmailAddressesFromDelimitedString = (val: string) =>
  uniq(parseCommaOrWhitespaceDelimitedList(val).filter((v) => validEmail.safeParse(v).success));

export interface InviteUsersFormData extends z.TypeOf<typeof validInviteUsersFormData> {}
