import { routes } from '../../../routes';
import { OnboardingStepKey } from './types';

export const steps = {
  [OnboardingStepKey.ISPDetails]: {
    key: OnboardingStepKey.ISPDetails,
    title: 'ISP details',
    route: routes.setup.location.form.isp,
  },
  [OnboardingStepKey.NetworkSetup]: {
    key: OnboardingStepKey.NetworkSetup,
    title: 'Network setup',
    route: routes.setup.location.form.mainNetwork,
  },
  [OnboardingStepKey.GuestNetwork]: {
    key: OnboardingStepKey.GuestNetwork,
    title: 'Guest network',
    route: routes.setup.location.form.guestNetwork,
  },
  [OnboardingStepKey.MeterInstall]: {
    key: OnboardingStepKey.MeterInstall,
    title: 'Meter install',
    route: routes.setup.location.form.meterInstall,
  },
} as const;
