import React from 'react';
import { useFocusVisible } from 'react-aria';
import { Outlet } from 'react-router';

import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { CurrentControllerOrErrorProvider } from '../../providers/CurrentControllerProvider';
import { focusVisibleClassName, styled } from '../../stitches';
import DesktopAppNavigation from '../Navigation/DesktopAppNavigation';
import MobileAppNavigation from '../Navigation/MobileAppNavigation';

const Container = styled('div', {
  height: '100%',
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'minmax(0, 1fr) min-content',
  gridTemplateAreas: '"content" "tab-bar"',
  '@sm': {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content minmax(0, 1fr)',
    gridTemplateAreas: '"header" "content"',
  },
});

const HeaderContainer = styled('div', {
  gridArea: 'header',
  display: 'none',
  '@sm': {
    display: 'block',
  },
  zIndex: 3,
});

const TabBarContainer = styled('div', {
  gridArea: 'tab-bar',
  display: 'block',
  '@sm': {
    display: 'none',
  },
  zIndex: 3,
});

const ContentContainer = styled('div', {
  gridArea: 'content',
  overflow: 'auto',
  zIndex: 1,
});

export const DashboardAppLayout = () => {
  const { isFocusVisible } = useFocusVisible();
  const flags = useFeatureFlags();

  return (
    <CurrentControllerOrErrorProvider>
      <Container className={isFocusVisible ? focusVisibleClassName : ''}>
        <HeaderContainer>
          <DesktopAppNavigation />
        </HeaderContainer>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
        {flags['meter-auth-ui'] && (
          <TabBarContainer>
            <MobileAppNavigation />
          </TabBarContainer>
        )}
      </Container>
    </CurrentControllerOrErrorProvider>
  );
};
