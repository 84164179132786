import { isDefinedAndNotEmpty } from '@meterup/common';
import { List, ListItemHeader, ListTitle } from '@meterup/metric';
import React from 'react';

import type { DeviceDataAndRadios } from '../../api/types';
import { routes } from '../../routes';
import { DEVICE_LIST_COLUMNS } from '../../utils/access_point_utils';
import { ListItemTableContainer } from '../ListItemTableContainer';
import { Table } from '../Table/Table';

export const DeviceListWidget = ({ devices }: { devices: DeviceDataAndRadios[] }) => (
  <List>
    <ListItemHeader>
      <ListTitle>Devices</ListTitle>
    </ListItemHeader>
    <ListItemTableContainer>
      <Table
        columns={DEVICE_LIST_COLUMNS}
        data={devices}
        linkProps={(device) =>
          isDefinedAndNotEmpty(device.device.name)
            ? {
                to: {
                  root: routes.network.devices.list.path,
                  drawer: routes.drawers.devices.detail.pathTo(device.device.name),
                },
              }
            : null
        }
        emptyStateHeading="No devices"
        shouldHideGlobalSearch
      />
    </ListItemTableContainer>
  </List>
);
