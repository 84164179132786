import type { ConditionalKeys } from 'type-fest';
import * as z from 'zod';

/**
 * Schema used to validate feature flags from LaunchDarkly and infer their types
 * for use throughout the app. Update this as you add or remove feature flags.
 * You will probably need to update LOCAL_FEATURE_FLAGS below as well
 */
export const KNOWN_FEATURE_FLAGS = z.object({
  'meter-auth-ui': z.boolean(),
  'tablet-splash-screen': z.boolean(),
  'visual-refresh': z.boolean(),
  'metrics-ui': z.boolean(),
  'cos-config': z.boolean(),
});

/**
 * Interface for all feature flags used in the app. Note that the interface has
 * a Partial modifier to reflect that flags may not be loaded before they are
 * first read.
 */
export interface MeterLDFlags extends Partial<z.TypeOf<typeof KNOWN_FEATURE_FLAGS>> {}

export const LOCAL_FEATURE_FLAGS: MeterLDFlags = {
  'meter-auth-ui': false,
  'tablet-splash-screen': true,
  'visual-refresh': true,
  'metrics-ui': true,
  'cos-config': true,
};

export type MeterLDFlagValue<Key extends keyof MeterLDFlags> = MeterLDFlags[Key];

/**
 * Union type of all possible boolean feature flags for convenience.
 */
export type BooleanMeterLDFlag = ConditionalKeys<z.TypeOf<typeof KNOWN_FEATURE_FLAGS>, boolean>;

/**
 * Convenience interface for features toggled by a boolean flag.
 */
export interface FeatureFlaggable {
  featureFlag?: BooleanMeterLDFlag;
}

export const getFeatureFlagPredicate = (flags: MeterLDFlags) => (entry: FeatureFlaggable) =>
  entry.featureFlag ? Boolean(flags[entry.featureFlag]) : true;
