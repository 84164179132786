import { createRoutesFromElements } from 'react-router';
import { Route } from 'react-router-dom';
import drawerRoutes from 'virtual:pagefiles/drawers';

import { Nav } from '../nav';
import { routes } from '../routes';
import { AddClientDrawer } from './Drawers/AddClientDrawer/AddClientDrawer';
import { ClientDetailDrawer } from './Drawers/ClientDetailDrawer';
import { CreateTokenDrawer } from './Drawers/CreateTokenDrawer';
import { DeviceDetailDrawer } from './Drawers/DeviceDetailDrawer';
import { InviteUsersDrawer } from './Drawers/InviteUsersDrawer/InviteUsersDrawer';
import { NotFoundDrawer } from './Drawers/NotFoundDrawer';
import { SSIDDetailDrawer } from './Drawers/SSIDDetailDrawer';
import { TokenDetailDrawer } from './Drawers/TokenDetailDrawer';
import { UserDetailDrawer } from './Drawers/UserDetailDrawer/UserDetailDrawer';

export const networkDrawerRoutes = createRoutesFromElements(
  <>
    <Route path={routes.drawers.clients.add.path} element={<AddClientDrawer />} />
    <Route path={routes.drawers.clients.detail.path} element={<ClientDetailDrawer />} />
    <Route path={routes.drawers.devices.detail.path} element={<DeviceDetailDrawer />} />
    <Route path={routes.drawers.clientTokens.detail.path} element={<TokenDetailDrawer />} />
    <Route path={routes.drawers.clientTokens.create.path} element={<CreateTokenDrawer />} />
    <Route path={routes.drawers.ssid.detail.path} element={<SSIDDetailDrawer />} />
    <Route path={routes.drawers.users.detail.path} element={<UserDetailDrawer />} />
    <Route path={routes.drawers.users.invite.path} element={<InviteUsersDrawer />} />
    <Route path="*" element={<NotFoundDrawer />} />
  </>,
);

export const DrawerRoutes = () =>
  Nav.useRoutes('drawer', [...drawerRoutes, ...networkDrawerRoutes]);
