import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Header, HeaderLogo, HeaderNavItem, HStack, space } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { useFocusVisible } from 'react-aria';
import { Outlet } from 'react-router';

import { Container, ContentContainer, HeaderContainer } from '../../components/app_layout';
import { ErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { HeaderDropdownMenu } from '../../components/HeaderDropdownMenu';
import { LoadingFallback } from '../../components/LoadingFallback';
import { LocationSwitcher } from '../../components/LocationSwitcher';
import { ReactRouterLink } from '../../components/ReactRouterLink';
import {
  CurrentControllerOrErrorProvider,
  CurrentControllerProvider,
} from '../../providers/CurrentControllerProvider';
import { SearchParamsStateProvider } from '../../providers/SearchParamsStateProvider';
import { focusVisibleClassName } from '../../stitches';

export const Meta: PagefileMetaFn = () => ({
  name: 'AppLayout',
});

export default function AppLayout() {
  const { isFocusVisible } = useFocusVisible();

  return (
    <SearchParamsStateProvider>
      <CurrentControllerProvider>
        <Container className={isFocusVisible ? focusVisibleClassName : ''}>
          <HeaderContainer>
            <Header
              navigation={
                <HStack align="center" spacing={space(8)}>
                  <LocationSwitcher />
                  <HeaderNavItem
                    as={ReactRouterLink}
                    to="/"
                    label="Network"
                    isSelected
                    icon="network"
                  />
                </HStack>
              }
              logo={<HeaderLogo />}
              controls={<HeaderDropdownMenu />}
            />
          </HeaderContainer>
          <ContentContainer>
            <ErrorBoundary fallback={ErrorFallback}>
              <Suspense fallback={<LoadingFallback />}>
                <CurrentControllerOrErrorProvider>
                  <Outlet />
                </CurrentControllerOrErrorProvider>
              </Suspense>
            </ErrorBoundary>
          </ContentContainer>
        </Container>
      </CurrentControllerProvider>
    </SearchParamsStateProvider>
  );
}
