import type { BadgeProps, BadgeVariant } from '@meterup/metric';
import { Badge } from '@meterup/metric';
import { api } from '@meterup/proto';
import { capitalize } from 'lodash';
import React from 'react';
import { match } from 'ts-pattern';

import type {
  DeviceStatus,
  EnabledDisabledUnknownStatus,
  OnlineOfflineUnknownStatus,
  RadioStatus,
} from '../api/types';
import type { ConnectionStatus } from '../utils/clientLists';

export const OnlineBadge = () => (
  <Badge ends="pill" size="small" variant="positive">
    Online
  </Badge>
);

export const OfflineBadge = () => (
  <Badge ends="pill" size="small" variant="neutral">
    Offline
  </Badge>
);

export const UnknownBadge = () => (
  <Badge ends="pill" size="small" variant="neutral">
    Unknown
  </Badge>
);

export const PendingBadge = () => (
  <Badge ends="pill" size="small" variant="neutral">
    Pending
  </Badge>
);

export const WiredBadge = () => (
  <Badge ends="pill" size="small" variant="neutral">
    Wired
  </Badge>
);

export const DisabledBadge = () => (
  <Badge ends="pill" size="small" variant="neutral">
    Disabled
  </Badge>
);

export const EnabledBadge = () => (
  <Badge ends="pill" size="small" variant="positive">
    Enabled
  </Badge>
);

export const OnlineOfflineStatusBadge = ({ status }: { status: OnlineOfflineUnknownStatus }) =>
  match(status)
    .with('online', () => <OnlineBadge />)
    .with('offline', () => <OfflineBadge />)
    .with('unknown', () => <UnknownBadge />)
    .exhaustive();

export const OnlineOfflineWifiIconBadge = ({ status }: { status: string }) =>
  match(status)
    .with('online', () => (
      <Badge icon="wifi" arrangement="hidden-label" ends="pill" size="small" variant="positive">
        Online
      </Badge>
    ))
    .otherwise(() => (
      <Badge icon="wifi" arrangement="hidden-label" ends="pill" size="small" variant="neutral">
        Offline
      </Badge>
    ));

export const ConnectionStatusBadge = ({ status }: { status: ConnectionStatus }) =>
  match(status)
    .with('online', () => <OnlineBadge />)
    .with('offline', () => <OfflineBadge />)
    .with('wired', () => <WiredBadge />)
    .exhaustive();

export const ISPStatusBadge = ({ status }: { status: api.ISPStatus }) =>
  match(status)
    .with(api.ISPStatus.IS_PRIMARY, () => (
      <Badge ends="card" size="small" variant="brand">
        Primary
      </Badge>
    ))
    .with(api.ISPStatus.IS_BACKUP, () => (
      <Badge ends="card" size="small" variant="neutral">
        Backup
      </Badge>
    ))
    .otherwise(() => <UnknownBadge />);

export type EnabledStatus = boolean | EnabledDisabledUnknownStatus;

export const EnabledStatusBadge = ({ status }: { status?: EnabledStatus }) =>
  match(status)
    .with(true, 'enabled', () => <EnabledBadge />)
    .with(false, 'disabled', () => <DisabledBadge />)
    .with('unknown', undefined, () => <UnknownBadge />)
    .exhaustive();

export const onlineOfflineBadgeVariant = (status: string): BadgeVariant =>
  match(status)
    .with('online', () => 'positive' as const)
    .with('offline', () => 'negative' as const)
    .otherwise(() => 'neutral' as const);

export const RadioStatusBadge = ({ status }: { status?: RadioStatus }) =>
  match(status)
    .with('online', 'enabled', () => (
      <Badge
        arrangement="leading-icon"
        size="small"
        variant="positive"
        ends="pill"
        icon="checkmarkCircle"
      >
        Enabled
      </Badge>
    ))
    .with('offline', 'disabled', () => (
      <Badge
        arrangement="leading-icon"
        size="small"
        variant="neutral"
        ends="pill"
        icon="crossCircle"
      >
        Disabled
      </Badge>
    ))
    .with(undefined, () => (
      <Badge size="small" variant="neutral" ends="pill">
        Unknown
      </Badge>
    ))
    .exhaustive();

export const DeviceStatusBadge = ({ status }: { status: DeviceStatus }) =>
  match(status)
    .with('online', () => <OnlineBadge />)
    .with('offline', () => <OfflineBadge />)
    .with('draft', () => (
      <Badge size="small" variant="neutral" ends="pill">
        Draft
      </Badge>
    ))
    .otherwise(() => <UnknownBadge />);

export const RotationStrategyBadge = ({ value }: { value: string }) => (
  <Badge variant="neutral" ends="pill" size="small" icon="arrowRotate" arrangement="leading-icon">
    {capitalize(value.toLocaleLowerCase())}
  </Badge>
);

export const UserRoleBadge = ({ role }: { role: api.CompanyMembershipRole }) =>
  match(role)
    .with(api.CompanyMembershipRole.admin, () => (
      <Badge size="small" variant="negative">
        Admin
      </Badge>
    ))
    .with(api.CompanyMembershipRole.member, () => (
      <Badge size="small" variant="neutral">
        Member
      </Badge>
    ))
    .with(api.CompanyMembershipRole.guest, () => (
      <Badge size="small" variant="neutral">
        Guest
      </Badge>
    ))
    .with(api.CompanyMembershipRole.unknown, () => (
      <Badge size="small" variant="neutral">
        Unknown
      </Badge>
    ))
    .exhaustive();

type CurriedBadgeProps = Partial<BadgeProps>;

export const NeutralBadge = ({
  ends = 'pill',
  size = 'small',
  variant = 'neutral',
  children,
  ...props
}: CurriedBadgeProps) => (
  <Badge {...props} ends={ends} size={size} variant={variant}>
    {children}
  </Badge>
);
