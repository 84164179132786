import { isDefined } from '@meterup/common';
import { Badge, Button, Logo, Subheading2 } from '@meterup/metric';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { BillingSetupFormData } from './BillingDrawer';
import { fetchOnboarding, updateOnboarding } from '../../api/api';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { routes } from '../../routes';
import { formatAddress } from '../../utils/formatAddress';
import { ReactRouterLink } from '../ReactRouterLink';
import { BillingDrawer } from './BillingDrawer';
import { Footer } from './Footer';
import { Container, Header, Main, MainContent, SetupFooterContent } from './layout';
import { StepButton } from './StepButton';
import { useCurrentCompanyLocation } from './useCurrentCompanyLocation';

const getBillingButtonStatus = (isBillingOpen: boolean, hasData: boolean) => {
  if (isBillingOpen) {
    return 'selected';
  }

  if (hasData) {
    return 'complete';
  }

  return 'pending';
};

enum SetupDrawerState {
  None,
  Billing,
  InviteUsers,
}

export const LocationDetail = () => {
  const company = useCurrentCompany();
  const location = useCurrentCompanyLocation();
  const onboarding = useQuery(
    ['onboarding', location?.sublocation_sid],
    () => fetchOnboarding(company, location!.sublocation_sid),
    { enabled: isDefined(location) },
  ).data;

  const [openDrawer, setOpenDrawer] = useState<SetupDrawerState>(SetupDrawerState.None);

  const closeDrawer = () => setOpenDrawer(SetupDrawerState.None);
  const openBilling = () => setOpenDrawer(SetupDrawerState.Billing);

  const client = useQueryClient();

  const updateBillingData = useMutation(
    (data: BillingSetupFormData) =>
      updateOnboarding({
        company_slug: company,
        sublocation_sid: location?.sublocation_sid,
        billing_contact_email: data.billingContactEmail,
      }),
    {
      onSuccess: closeDrawer,
      onSettled: () => {
        client.invalidateQueries('onboarding');
      },
    },
  );

  // FIXME: The API endpoint expects a single email address at a time, but this
  // code was written expecting it to take a list. The code isn't visible but it
  // needs fixing before it will work.
  // const inviteUsersData = useMutation((emails: string[]) => createUser(company, emails), {
  //   onSuccess: closeDrawer,
  //   onSettled: () => {
  //     client.invalidateQueries('onboarding');
  //   },
  // });

  const isOnboardingComplete = onboarding?.status === 'information_complete';
  const isBillingComplete = !isEmpty(onboarding?.billing_contact_email);

  return location ? (
    <Container layout={openDrawer !== SetupDrawerState.None ? 'main-and-drawer' : 'main-only'}>
      <Header>
        <Logo />
      </Header>
      <Main>
        <MainContent>
          <Button
            as={ReactRouterLink}
            to={routes.setup.root.path}
            direction="previous"
            variant="tertiary"
            size="small"
          >
            Change location
          </Button>

          <Subheading2>
            Network setup for{' '}
            <Badge variant="brand">
              {isDefined(location.address) ? formatAddress(location.address) : 'No address'}
            </Badge>
          </Subheading2>

          <StepButton
            as={ReactRouterLink}
            to={routes.setup.location.form.root.pathTo(location.sublocation_sid)}
            status={isOnboardingComplete ? 'complete' : 'pending'}
            icon={isOnboardingComplete ? 'checkmark' : 'attention'}
            title="Installation details"
            description="Configure your network and provide us with information needed to start installing your Meter Network."
          />

          <StepButton
            status={getBillingButtonStatus(
              openDrawer === SetupDrawerState.Billing,
              isBillingComplete,
            )}
            icon={isBillingComplete ? 'checkmark' : 'attention'}
            title="Billing setup"
            description="Send Meter’s vendor details and invoice to your billing software or Accounts Payable department."
            onClick={openBilling}
          />
          {/* TODO: Sort out invites 403ing for non-NOC users before enabling this UI */}
          {/* <StepButton */}
          {/*  status={openDrawer === SetupDrawerState.InviteUsers ? 'selected' : 'pending'} */}
          {/*  title="Invite admin users" */}
          {/*  description="Who else should have full access and privileges to Meter Dashboard?" */}
          {/*  onClick={openInviteUsers} */}
          {/* /> */}
        </MainContent>
      </Main>
      <SetupFooterContent>
        <Footer />
      </SetupFooterContent>
      {openDrawer === SetupDrawerState.Billing && (
        <BillingDrawer
          initialData={{ billingContactEmail: onboarding?.billing_contact_email ?? '' }}
          isSaving={updateBillingData.isLoading}
          onClose={closeDrawer}
          onSubmit={updateBillingData.mutate}
        />
      )}
      {/* {openDrawer === SetupDrawerState.InviteUsers && ( */}
      {/*  <InviteUsersDrawer */}
      {/*    initialData={{ userListCommaSeparated: '' }} */}
      {/*    onClose={closeDrawer} */}
      {/*    onSubmit={inviteUsersData.mutate} */}
      {/*  /> */}
      {/* )} */}
    </Container>
  ) : null;
};
