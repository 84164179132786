import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { fetchCompanyLocation } from '../../api/api';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';

export const useCurrentCompanyLocation = () => {
  const params = useParams<{ location: string }>();
  const company = useCurrentCompany();

  const location = useQuery(['location', company, params.location], () =>
    fetchCompanyLocation(company, params.location!),
  ).data;

  return location ?? null;
};
