import {
  BasicSelect,
  BasicSelectItem,
  Button,
  CompositeField,
  Heading,
  HStack,
  List,
  PrimaryField,
  SecondaryField,
  SecondaryFieldComposite,
  space,
  TextInput,
  ToggleInput,
  VStack,
} from '@meterup/metric';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';

import type { FrequencyInterval, OnboardingFormData } from '../types';
import { FieldProvider } from '../../../FieldProvider';
import { PasswordStrengthIndicator } from '../../../PasswordStrengthIndicator';
import { BackButton } from '../components/BackButton';
import { NextStepButton } from '../components/NextStepButton';
import { WordCountListItem } from '../components/WordCountListItem';
import { useSuggestPasswordState } from '../hooks/useSuggestPasswordState';

interface RotationIntervalOption {
  key: FrequencyInterval;
  name: string;
}

const ROTATION_INTERVAL_OPTIONS: RotationIntervalOption[] = [
  { key: 'hour', name: 'Hours' },
  { key: 'day', name: 'Days' },
  { key: 'week', name: 'Weeks' },
];

export const GuestNetworkStep: React.FC = () => {
  const form = useFormikContext<OnboardingFormData>();

  // Automatically populate the SSID with "<main network SSID> Guest"
  useEffect(() => {
    if (
      form.values.guestNetworkData.ssid === '' &&
      form.values.mainNetworkData.ssid !== '' &&
      !form.touched.guestNetworkData?.ssid
    ) {
      form.setFieldValue('guestNetworkData.ssid', `${form.values.mainNetworkData.ssid} Guest`);
    }
  }, [form]);

  const onSetPassword = useCallback(
    (pwd: string) => {
      form.setFieldValue('guestNetworkData.password', pwd);
    },
    [form],
  );

  const { wordCount, setWordCount, refreshPassword } = useSuggestPasswordState(onSetPassword);

  return (
    <VStack spacing={space(16)}>
      <Heading>Guest network</Heading>
      <List>
        <FieldProvider name="guestNetworkData.ssid">
          <PrimaryField label="Guest SSID" element={<TextInput autoComplete="off" autoFocus />} />
        </FieldProvider>
      </List>
      <List>
        <FieldProvider name="guestNetworkData.password">
          <PrimaryField
            label="Guest password"
            element={<TextInput autoComplete="off" autoFocus />}
            controls={
              <>
                <PasswordStrengthIndicator password={form.values.guestNetworkData.password ?? ''} />
                <Button
                  size="small"
                  icon="arrowRotate"
                  type="button"
                  arrangement="hidden-label"
                  variant="secondary"
                  onClick={refreshPassword}
                >
                  Suggest a password
                </Button>
              </>
            }
          />
        </FieldProvider>
        <WordCountListItem value={wordCount} onChange={setWordCount} />
        <FieldProvider name="guestNetworkData.autoRotatePassword">
          <SecondaryField
            label="Auto-rotate password"
            element={<ToggleInput selected={form.values.guestNetworkData.autoRotatePassword} />}
          />
        </FieldProvider>
        {form.values.guestNetworkData.autoRotatePassword && (
          <SecondaryFieldComposite
            label="Rotate every"
            fields={
              <>
                <FieldProvider name="guestNetworkData.rotateEveryValue">
                  <CompositeField
                    label="Password rotation frequency"
                    element={<TextInput type="number" />}
                  />
                </FieldProvider>
                <FieldProvider name="guestNetworkData.rotateEveryUnit">
                  <CompositeField
                    label="Password rotation interval"
                    element={
                      <BasicSelect>
                        {ROTATION_INTERVAL_OPTIONS.map((item) => (
                          <BasicSelectItem key={item.key} value={item.key}>
                            {item.name}
                          </BasicSelectItem>
                        ))}
                      </BasicSelect>
                    }
                  />
                </FieldProvider>
              </>
            }
          />
        )}
      </List>
      <HStack justify="between">
        <BackButton />
        <NextStepButton requireValidationAtPath="guestNetworkData" />
      </HStack>
    </VStack>
  );
};
