import { Button } from '@meterup/metric';
import React from 'react';

import { useTimedToggle } from '../hooks/useTimedToggle';
import { styled } from '../stitches';
import { CircularProgressGauge } from './CircularProgressGauge';
import { CopyToClipboardButtonMinimal } from './CopyToClipboardButton';
import { HiddenPassword } from './HiddenPassword';

const PasswordViewerContainer = styled('div', {
  hStack: '$8',
});

export const PasswordViewer = ({ password }: { password: string }) => {
  const { value: isShowingPassword, progress, toggle } = useTimedToggle(30 * 1000);

  return (
    <PasswordViewerContainer>
      {isShowingPassword ? <span>{password}</span> : <HiddenPassword />}{' '}
      <Button
        arrangement={!isShowingPassword ? 'hidden-label' : undefined}
        icon={!isShowingPassword ? 'eyeOpen' : undefined}
        size="small"
        variant="secondary"
        onClick={toggle}
      >
        {isShowingPassword ? (
          <CircularProgressGauge progress={1 - progress} size={12} strokeWidth={1.5} />
        ) : (
          'Show password'
        )}
      </Button>
      <CopyToClipboardButtonMinimal text={password} />
    </PasswordViewerContainer>
  );
};
