import { ProviderIconSVG } from 'atto-svgs';
import React from 'react';

export enum ProviderIconProvider {
  ATT = 'att',
  Cogent = 'cogent',
  ComcastBusiness = 'comcast-business',
  Lumen = 'lumen',
  Sonic = 'sonic',
  Spectrum = 'spectrum',
  Verizon = 'verizon',
  Zayo = 'zayo',
}

export interface ProviderIconProps {
  /**
   * Set the color. **Note:** Does not accept the full [colorset](/?path=/docs/colors).
   */
  color?: 'brand' | 'monotone' | 'adaptive';
  /**
   * Set which provider should be displayed.
   */
  provider: ProviderIconProvider;
}

export const ProviderIcon: React.FunctionComponent<ProviderIconProps> = ({
  color = 'adaptive',
  provider,
}) =>
  ({
    adaptive: {
      [ProviderIconProvider.ATT]: <ProviderIconSVG.ATTAdaptive />,
      [ProviderIconProvider.Cogent]: <ProviderIconSVG.CogentAdaptive />,
      [ProviderIconProvider.ComcastBusiness]: <ProviderIconSVG.ComcastBusinessAdaptive />,
      [ProviderIconProvider.Lumen]: <ProviderIconSVG.LumenAdaptive />,
      [ProviderIconProvider.Sonic]: <ProviderIconSVG.SonicAdaptive />,
      [ProviderIconProvider.Spectrum]: <ProviderIconSVG.SpectrumAdaptive />,
      [ProviderIconProvider.Verizon]: <ProviderIconSVG.VerizonAdaptive />,
      [ProviderIconProvider.Zayo]: <ProviderIconSVG.ZayoAdaptive />,
    }[provider],
    brand: {
      [ProviderIconProvider.ATT]: <ProviderIconSVG.ATTBrand />,
      [ProviderIconProvider.Cogent]: <ProviderIconSVG.CogentBrand />,
      [ProviderIconProvider.ComcastBusiness]: <ProviderIconSVG.ComcastBusinessBrand />,
      [ProviderIconProvider.Lumen]: <ProviderIconSVG.LumenBrand />,
      [ProviderIconProvider.Sonic]: <ProviderIconSVG.SonicBrand />,
      [ProviderIconProvider.Spectrum]: <ProviderIconSVG.SpectrumBrand />,
      [ProviderIconProvider.Verizon]: <ProviderIconSVG.VerizonBrand />,
      [ProviderIconProvider.Zayo]: <ProviderIconSVG.ZayoBrand />,
    }[provider],
    monotone: {
      [ProviderIconProvider.ATT]: <ProviderIconSVG.ATTMono />,
      [ProviderIconProvider.Cogent]: <ProviderIconSVG.CogentMono />,
      [ProviderIconProvider.ComcastBusiness]: <ProviderIconSVG.ComcastBusinessMono />,
      [ProviderIconProvider.Lumen]: <ProviderIconSVG.LumenMono />,
      [ProviderIconProvider.Sonic]: <ProviderIconSVG.SonicMono />,
      [ProviderIconProvider.Spectrum]: <ProviderIconSVG.SpectrumMono />,
      [ProviderIconProvider.Verizon]: <ProviderIconSVG.VerizonMono />,
      [ProviderIconProvider.Zayo]: <ProviderIconSVG.ZayoMono />,
    }[provider],
  }[color]);

export default ProviderIcon;
