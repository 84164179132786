import { expectDefinedOrThrow, getControllerVersion, isDefined } from '@meterup/common';
import { api } from '@meterup/proto';
import { first } from 'lodash';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { fetchControllers } from '../api/api';
import { FatalErrorFallback } from '../components/ErrorFallback/ErrorFallback';
import { NoHardwareError } from '../errors';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { logError } from '../utils/logError';
import { useCurrentCompany } from './CurrentCompanyProvider';

interface CurrentControllerData {
  currentController: api.ControllerResponse | null;
  changeCurrentController: (slug: string) => void;
}

const CurrentControllerContext = createContext<CurrentControllerData>({} as any);

const pickCurrentController = (
  availableControllers: api.ControllerResponse[],
  preferredControllerSlug: string | null,
) => {
  const preferredController = availableControllers.find((d) => d.name === preferredControllerSlug);

  if (isDefined(preferredController)) {
    return preferredController;
  }

  const firstInstalledController = availableControllers.find(
    (c) => c.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY,
  );

  return firstInstalledController ?? first(availableControllers) ?? null;
};

/**
 * CurrentControllerProvider provides the user's currently selected controller
 * or the first controller the user can access within their current company.
 */
export const CurrentControllerProvider: React.FC = ({ children }) => {
  const currentCompany = useCurrentCompany();

  const controllers = useQuery(
    ['controllers', currentCompany],
    () => fetchControllers(currentCompany),
    { suspense: true },
  ).data;

  const [preferredControllerSlug, setPreferredControllerSlug] = useLocalStorage<string | null>(
    'currentController',
    null,
  );

  expectDefinedOrThrow(controllers);

  const currentController = pickCurrentController(controllers, preferredControllerSlug);

  useEffect(() => {
    if (isDefined(currentController)) {
      setPreferredControllerSlug(currentController.name);
    }
  }, [currentController, setPreferredControllerSlug]);

  const value = useMemo(
    () => ({
      currentController,
      changeCurrentController: setPreferredControllerSlug,
    }),
    [currentController, setPreferredControllerSlug],
  );

  return value ? (
    <CurrentControllerContext.Provider value={value}>{children}</CurrentControllerContext.Provider>
  ) : null;
};

const CurrentControllerOrErrorContext = createContext<api.ControllerResponse>(null as any);

export const CurrentControllerOrErrorProvider = ({ children }: { children?: React.ReactNode }) => {
  const { currentController } = useContext(CurrentControllerContext);

  const error = useMemo(
    () =>
      new NoHardwareError(
        'No associated hardware',
        'Your organization has no associated Meter hardware. Please contact support.',
      ),
    [],
  );

  useEffect(() => {
    if (!isDefined(currentController)) {
      logError(error);
    }
  }, [currentController, error]);

  return isDefined(currentController) ? (
    <CurrentControllerOrErrorContext.Provider value={currentController}>
      {children}
    </CurrentControllerOrErrorContext.Provider>
  ) : (
    <FatalErrorFallback error={error!} componentStack={null} />
  );
};

export const useCurrentControllerMaybeNull = () => {
  const val = useContext(CurrentControllerContext).currentController?.name ?? null;

  if (!isDefined(val)) {
    throw new Error('useCurrentControllerMaybeNull must be used within a CurrentControllerContext');
  }

  return val;
};

export const useChangeCurrentControllerCallback = () => {
  const val = useContext(CurrentControllerContext).changeCurrentController;

  if (!isDefined(val)) {
    throw new Error(
      'useChangeCurrentControllerCallback must be used within a CurrentControllerContext',
    );
  }

  return val;
};

export const useCurrentController = () => {
  const val = useContext(CurrentControllerOrErrorContext);

  if (!isDefined(val)) {
    throw new Error('useCurrentController must be used within a CurrentControllerOrErrorProvider');
  }

  return val.name;
};

export const useCurrentControllerData = () => {
  const val = useContext(CurrentControllerOrErrorContext);

  if (!isDefined(val)) {
    throw new Error(
      'useCurrentControllerData must be used within a CurrentControllerOrErrorProvider',
    );
  }

  return val;
};

export const useCurrentControllerVersion = () => getControllerVersion(useCurrentControllerData());
