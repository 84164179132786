import {
  colors,
  darkThemeSelector,
  fontWeights,
  shadows,
  styled,
} from '../../../../stitches.config';
import { Body2 } from '../../../../text/Body';
import { Small2 } from '../../../../text/Small';
import ListItem from '../../../List/ListItem';
import ListItemLabel from '../../../List/ListItemLabel';

export const PrimaryFieldLabel = styled('label', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-200'],
  },
});

export const SecondaryFieldLabel = styled('label', ListItemLabel, {
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-200'],
  },
});

export const ErrorMessage = styled('div', ListItem, {
  position: 'relative',
  fontSize: '$14',
  minHeight: 'unset',
  lineHeight: '$20',
  color: colors['red-700'],
  backgroundColor: colors['red-50'],
  padding: '$4 $8 $4 $16',
  boxShadow: shadows.fenceErrorAllLight,
  zIndex: 1,
  // margin: '0 0.5px',
  '&:before': {
    position: 'absolute',
    content: '" "',
    display: 'block',
    width: '$4',
    left: '$4',
    top: '$4',
    bottom: '$4',
    borderRadius: '$4',
    backgroundColor: colors['red-600'],
  },
  [darkThemeSelector]: {
    color: colors['red-50'],
    backgroundColor: colors['red-900'],
    boxShadow: shadows.fenceErrorAllDark,
    '&:before': {
      backgroundColor: colors['red-300'],
    },
  },
});

export const FieldDescription = styled('div', Small2, {
  color: colors['gray-600'],
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
});
