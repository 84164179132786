import type { Path, To } from 'history';
import { createPath } from 'history';

import { isDefined } from './isDefined';

const isPartialPathObject = (value: unknown): value is Partial<Path> =>
  typeof value === 'object' &&
  value != null &&
  ('pathname' in value ||
    'search' in value ||
    'state' in value ||
    'hash' in value ||
    'key' in value);

const buildRegionAwareSearchParams = (
  regions: MultiRegionTo,
  additionalSearchParams: URLSearchParams,
) => {
  const searchParams = new URLSearchParams(additionalSearchParams);

  Object.entries(regions).forEach(([regionName, regionPathOrLocation]) => {
    if (isDefined(regionPathOrLocation)) {
      if (isPartialPathObject(regionPathOrLocation)) {
        searchParams.set(regionName, createPath(regionPathOrLocation));
      } else {
        searchParams.set(regionName, regionPathOrLocation);
      }
    }
  });

  // NOTE: Un-escaping some entities purely for cosmetic reasons because it
  // looks more readable in the URL bar. These entities are serialized to the
  // search params in the URL, so they should be safe to un-escape. If this
  // causes issues, definitely remove it!
  return searchParams.toString().replaceAll('%2F', '/').replaceAll('%3A', ':');
};

const getRootPathName = (toPathOrDescriptor: To) => {
  if (isPartialPathObject(toPathOrDescriptor)) {
    return toPathOrDescriptor.pathname;
  }

  return toPathOrDescriptor;
};

const getRootSearchParams = (toPathOrDescriptor: To) => {
  if (isPartialPathObject(toPathOrDescriptor)) {
    return toPathOrDescriptor.search;
  }

  return undefined;
};

type MultiRegionTo = {
  [key: string]: To | null | undefined;
};

export const makeTo = (to: To | MultiRegionTo): To => {
  if (typeof to === 'string') {
    return {
      pathname: to,
    };
  }

  if (isPartialPathObject(to)) {
    return to;
  }

  const { root, ...regions } = to;
  const rootPathname = root ? getRootPathName(root) : undefined;
  const rootSearchParams = new URLSearchParams(root ? getRootSearchParams(root) : undefined);
  const search = buildRegionAwareSearchParams(regions, rootSearchParams);

  return {
    pathname: rootPathname,
    search,
  };
};
