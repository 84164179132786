import {
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import React from 'react';
import { Route } from 'react-router-dom';

import { useDrawerParams } from '../../../hooks/useDrawerParams';
import { useNavigateMainAndDrawerCallback } from '../../../hooks/useNavigateMainAndDrawerCallback';
import { Nav } from '../../../nav';
import { useCurrentCompanyMembership } from '../../../providers/CurrentCompanyProvider';
import { routes } from '../../../routes';
import { CloseDrawerButton } from '../../CloseDrawerButton';
import { RemoveUserContent } from './RemoveUserContent';
import { UserDetails } from './UserDetails';

const DRAWER_TITLE = 'User';

const UserControls = () => {
  const userSid = useDrawerParams(routes.drawers.users.detail.path)!.userSid as string;
  const navigateFn = useNavigateMainAndDrawerCallback();
  const currentUser = useCurrentCompanyMembership();

  return (
    <DropdownMenu>
      <DropdownMenuTriggerButton
        icon="overflowVertical"
        arrangement="hidden-label"
        variant="secondary"
      >
        Actions
      </DropdownMenuTriggerButton>

      <DropdownMenuContent>
        <DropdownMenuItem
          onSelect={() => navigateFn({ drawer: routes.drawers.users.remove.pathTo(userSid) })}
          disabled={userSid === currentUser.sid}
        >
          Remove user
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const UserDetailDrawer = () => (
  <Drawer>
    <Nav.Routes regionName="drawer">
      <Route
        path={routes.drawers.users.remove.path}
        element={
          <>
            <DrawerHeader>
              <DrawerTitle>Remove user</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <RemoveUserContent />
          </>
        }
      />
      <Route
        path={routes.drawers.users.detail.path}
        element={
          <>
            <DrawerHeader>
              <DrawerTitle>{DRAWER_TITLE}</DrawerTitle>
              <DrawerControls>
                <UserControls />
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              <UserDetails />
            </DrawerContent>
          </>
        }
      />
    </Nav.Routes>
  </Drawer>
);
