import { TabBar, TabBarNavItem } from '@meterup/metric';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getFeatureFlagPredicate } from '../../feature_flags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { appNavigationEntries, getActiveKey } from './appNavigationEntries';

const MobileAppNavigation = () => {
  const location = useLocation();
  const activeKey = getActiveKey(location.pathname);
  const flags = useFeatureFlags();
  return (
    <TabBar>
      {appNavigationEntries.filter(getFeatureFlagPredicate(flags)).map((entry) => (
        <TabBarNavItem
          as={Link}
          to={entry.path}
          label={entry.label}
          key={entry.key}
          isSelected={entry.key === activeKey}
          icon={entry.icon}
        />
      ))}
    </TabBar>
  );
};

export default MobileAppNavigation;
