import React from 'react';

import Box from '../../utilities/Box/Box';
import { space } from '../../utilities/shared/sizes';

export interface ProgressBarProps {
  progressPercentage: number;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({ progressPercentage }) => (
  <Box
    position="relative"
    display="flex"
    height={space(4)}
    bg={{ light: 'brand-300', dark: 'brand-800' }}
    overflow={{ all: 'hidden' }}
    radius={{ all: 'full' }}
  >
    <Box
      display="flex"
      bg={{ light: 'brand-600', dark: 'brand-600' }}
      radius={{ all: 'full' }}
      className="m-transition m-duration-150 m-ease-in-out "
      style={{ width: `${progressPercentage}%` }}
    />
  </Box>
);

export default ProgressBar;
