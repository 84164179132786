import { Box, HStack, Small, space, VStack } from '@meterup/metric';
import React from 'react';
import { match } from 'ts-pattern';

import building from './art/Building.png';
import controller from './art/Controller.png';
import apLight from './art/MW03.png';
import apDarkLED from './art/MW03-led.png';
import { DiscreteProgressBar } from './components/DiscreteProgressBar';
import { useCurrentOnboardingStep } from './hooks/useCurrentOnboardingStep';
import { OnboardingStepKey } from './types';

const AccessPointArt: React.FC = () => (
  <>
    <img src={apLight} alt="Meter Access Point" className="dark:d-mix-blend-overlay d-w-full" />
    <img
      src={apDarkLED}
      alt="LED status light"
      className="d-invisible dark:d-visible d-absolute d-inset-0"
      aria-hidden
    />
  </>
);

const ControllerArt: React.FC = () => (
  <img src={controller} alt="Meter Controller" className="dark:d-mix-blend-overlay d-w-full" />
);

const BuildingArt: React.FC = () => (
  <img
    src={building}
    alt="Illustration of a building"
    className="dark:d-mix-blend-overlay"
    style={{ maxWidth: 120 }}
  />
);

export const SideContent: React.FC = () => {
  const step = useCurrentOnboardingStep();

  return step ? (
    <div className="d-flex d-flex-col d-w-full d-items-center d-px-20" style={{ maxWidth: 536 }}>
      {/* TODO: Use a more browser-compatible aspect ratio solution */}
      <Box
        position="relative"
        width="full"
        style={{
          maxWidth: 300,
          flex: 1,
          aspectRatio: '1 / 1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {match(step.key as OnboardingStepKey)
          .with(OnboardingStepKey.ISPDetails, () => <ControllerArt />)
          .with(OnboardingStepKey.NetworkSetup, () => <AccessPointArt />)
          .with(OnboardingStepKey.GuestNetwork, () => <AccessPointArt />)
          .with(OnboardingStepKey.MeterInstall, () => <BuildingArt />)
          .run()}
      </Box>
      <VStack width="full" spacing={space(12)}>
        <HStack justify="between">
          <Small color={{ light: 'gray-600', dark: 'gray-50' }} weight="medium">
            {step.title}
          </Small>
          <Small color={{ light: 'gray-600', dark: 'gray-50' }} weight="medium">
            {step.key + 1} of 4
          </Small>
        </HStack>
        <DiscreteProgressBar value={step.key + 1} maxValue={4} />
      </VStack>
    </div>
  ) : null;
};
