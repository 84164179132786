import { DeviceStatusBadge, isDefined, RadioStatusBadge } from '@meterup/common';
import {
  Alert,
  DeviceIcon,
  DeviceIconSize,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  Heading2,
  List,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchDevice } from '../../api/api';
import { Nav } from '../../nav';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { styled } from '../../stitches';
import { CloseDrawerButton } from '../CloseDrawerButton';
import { ClientConnectionHistoryWidget } from '../Widgets/ClientConnectionHistoryWidget';

const DRAWER_TITLE = routes.drawers.devices.detail.label;

const DeviceName = styled('div', {
  vStack: '$16',
  marginBottom: '$16',
});
const DeviceDetails = () => {
  const controller = useCurrentController();
  const { deviceName } = Nav.useRegionParams('drawer', routes.drawers.devices.detail.path)!;

  const { data: device } = useQuery(
    ['device', controller, deviceName],
    () => fetchDevice(controller, deviceName),
    {
      suspense: true,
    },
  );

  return isDefined(device) ? (
    <DrawerContent>
      <DeviceName>
        <DeviceIcon size={DeviceIconSize.Large} />
        <Heading2>{device.physical_location ?? device.name}</Heading2>
      </DeviceName>
      <List>
        <ListItemPair>
          <ListItemLabel>Status</ListItemLabel>
          <ListItemValue>
            <DeviceStatusBadge status={device.status} />
          </ListItemValue>
        </ListItemPair>
        <ListItemPair>
          <ListItemLabel>2.4 GHz</ListItemLabel>
          <ListItemValue>
            <RadioStatusBadge status={device.radio_status['2G']} />
          </ListItemValue>
        </ListItemPair>
        <ListItemPair>
          <ListItemLabel>5 GHz</ListItemLabel>
          <ListItemValue>
            <RadioStatusBadge status={device.radio_status['5G']} />
          </ListItemValue>
        </ListItemPair>
      </List>
      <ClientConnectionHistoryWidget device={device} />
    </DrawerContent>
  ) : (
    <DrawerContent>
      <Alert copy="Device not found" />
    </DrawerContent>
  );
};
export const DeviceDetailDrawer = () => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>{DRAWER_TITLE}</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DeviceDetails />
  </Drawer>
);
