import { bytesPerSecond, formatDataRateBits } from '@meterup/common';
import React, { useCallback } from 'react';
import { useQuery } from 'react-query';

import { fetchWANThroughputMetrics } from '../api/api';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { PageHeader, PageSection, PageTitle } from './Page/Page';
import { Box, TimeSeriesChart } from './TimeSeriesChart';

export const WANThroughputMetrics = () => {
  const controller = useCurrentController();

  const metricsData = useQuery(['controller', controller, 'metrics', 'throughput'], () =>
    fetchWANThroughputMetrics(controller, { downsampleRate: 64 }),
  ).data;

  const valueFormatter = useCallback(
    (value: number) => formatDataRateBits(value, bytesPerSecond),
    [],
  );

  return metricsData ? (
    <PageSection>
      <PageHeader>
        <PageTitle>WAN throughput</PageTitle>
      </PageHeader>
      <Box css={{ padding: '$20', vStack: '$16', alignItems: 'stretch' }}>
        <TimeSeriesChart
          title="wan0 Rx"
          series={metricsData.seriesList.wan0rx}
          valueFormatter={valueFormatter}
        />
        <TimeSeriesChart
          title="wan0 Tx"
          series={metricsData.seriesList.wan0tx}
          valueFormatter={valueFormatter}
        />
        <TimeSeriesChart
          title="wan1 Rx"
          series={metricsData.seriesList.wan1rx}
          valueFormatter={valueFormatter}
        />
        <TimeSeriesChart
          title="wan1 Tx"
          series={metricsData.seriesList.wan1tx}
          valueFormatter={valueFormatter}
        />
      </Box>
    </PageSection>
  ) : null;
};
