// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';

import type { BooleanMeterLDFlag, MeterLDFlags, MeterLDFlagValue } from '../feature_flags';
import { LOCAL_FEATURE_FLAGS } from '../feature_flags';

export const useFeatureFlags = (): MeterLDFlags => {
  const flags = useFlags() as MeterLDFlags;

  if (import.meta.env.REALM === 'local') {
    return LOCAL_FEATURE_FLAGS;
  }

  return flags;
};

export const useFeatureFlag = <Key extends BooleanMeterLDFlag>(key: Key): MeterLDFlagValue<Key> =>
  useFeatureFlags()[key];
