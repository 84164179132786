import { useLocation } from 'react-router';

import { matchPathFromLocation } from '../../../../utils/locations';
import { steps } from '../steps';

export const useCurrentOnboardingStep = () => {
  const currentLocation = useLocation();
  return Object.values(steps).find(({ route }) =>
    Boolean(
      matchPathFromLocation(route.path, currentLocation, {
        exact: true,
        sensitive: false,
      }),
    ),
  );
};
