import { Button } from '@meterup/metric';
import React from 'react';

import { useTimedToggle } from '../hooks/useTimedToggle';
import { copyTextToClipboard } from '../utils/clipboard';

interface CopyToClipboardButtonProps {
  text: string;
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ text }) => {
  const { value, show } = useTimedToggle();

  const handleClick = async () => {
    await copyTextToClipboard(text);
    show();
  };

  return (
    <Button
      arrangement="leading-icon"
      size="small"
      variant="secondary"
      icon={value ? 'checkmarkCircle' : 'copy'}
      width="full"
      onClick={handleClick}
    >
      {value ? 'Copied' : 'Copy'}
    </Button>
  );
};

export const CopyToClipboardButtonMinimal: React.FC<CopyToClipboardButtonProps> = ({ text }) => {
  const { value, show } = useTimedToggle();

  const handleClick = async () => {
    await copyTextToClipboard(text);
    show();
  };

  return (
    <Button
      arrangement="hidden-label"
      size="small"
      variant="secondary"
      icon={value ? 'checkmarkCircle' : 'copy'}
      onClick={handleClick}
    >
      Copy to clipboard
    </Button>
  );
};
