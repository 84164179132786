import type { IconName } from '@meterup/metric';
import { Tab, Tabs } from '@meterup/metric';
import React from 'react';

import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { ReactRouterLink } from '../ReactRouterLink';

const ALL_FILTER = () => true;

export interface FilterDef<Data extends object, Key extends string = string> {
  key: Key;
  label: React.ReactNode;
  icon?: IconName;
  predicate?: (row: Data) => boolean;
}

export function createFilterSet<Data extends object, Key extends string = string>(
  filters: FilterDef<Data, Key>[],
  {
    defaultKey = filters[0]?.key,
    urlKey = 'filter',
  }: {
    defaultKey?: Key;
    urlKey?: string;
  } = {},
) {
  const useCurrentFilterKey = () => {
    const [currentFilter] = useSearchParamsState<string>(urlKey, defaultKey);
    return currentFilter ?? defaultKey;
  };

  const useCurrentFilter = () => {
    const filterKey = useCurrentFilterKey();
    return filters.find((f) => f.key === filterKey);
  };

  const useFilteredData = (data: Data[]) => {
    const filter = useCurrentFilter();
    return filter ? data.filter(filter.predicate ?? ALL_FILTER) : data;
  };

  const TabSwitcher = ({ data }: { data?: Data[] }) => {
    const currentKey = useCurrentFilterKey();
    const [, , nextPath] = useSearchParamsState<string>(urlKey, defaultKey);

    return (
      <Tabs>
        {filters.map((filter) => (
          <Tab
            key={filter.key}
            as={ReactRouterLink}
            to={nextPath(filter.key)}
            active={currentKey === filter.key}
            annotation={data?.filter(filter.predicate ?? ALL_FILTER).length}
            icon={filter.icon}
            replace
          >
            {filter.label}
          </Tab>
        ))}
      </Tabs>
    );
  };

  return {
    TabSwitcher,
    useCurrentFilterKey,
    useCurrentFilter,
    useFilteredData,
  };
}
