import type { ExtractRouteParams } from '@meterup/react-router-extensions';
import type { Pathname, Search } from 'history';
import { generatePath } from 'react-router';

import { Nav } from '../nav';

export const makeLink = <T extends string>(path: T, args: ExtractRouteParams<T, string>): string =>
  // @ts-expect-error
  generatePath(path, args);

type PathAndSearch = {
  pathname: Pathname;
  search: Search;
};

export const makeDrawerLink = <T extends string>(
  currentLocation: PathAndSearch,
  path: T,
  args: ExtractRouteParams<T, string>,
) =>
  Nav.makeTo({
    root: {
      pathname: currentLocation.pathname,
      search: currentLocation.search,
    },
    drawer: `${makeLink(path, args)}`,
  });

const mergeSearchParams = (a: URLSearchParams, b: Record<string, string | null | undefined>) => {
  const newSearchParams = new URLSearchParams(a);
  Object.entries(b).forEach(([key, value]) => {
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
  });
  return newSearchParams;
};

export const makeParamsLink = (
  currentLocation: PathAndSearch,
  newParams: Record<string, string | null>,
) => {
  const drawer = Nav.getRegionLocation('drawer', currentLocation);
  const existingParams = new URLSearchParams(currentLocation.search);

  return Nav.makeTo({
    root: {
      pathname: currentLocation.pathname,
      search: mergeSearchParams(existingParams, newParams).toString(),
    },
    drawer: drawer?.pathname,
  });
};

export const makeCloseDrawerLink = (currentRootLocation: string) =>
  Nav.makeTo({ root: currentRootLocation, drawer: null });
