import { Box, Button } from '@meterup/metric';
import React from 'react';

import { useOnboardingNavigation } from '../hooks/useOnboardingNavigation';

export const BackButton: React.FC = () => {
  const { goBack } = useOnboardingNavigation();

  return (
    <Box width="full">
      <Button
        variant="tertiary"
        icon="arrowLeft"
        size="large"
        arrangement="leading-icon"
        type="button"
        onClick={goBack}
      >
        Back
      </Button>
    </Box>
  );
};
