import type React from 'react';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import type { OnboardingData } from '../../../../api/types';
import type { OnboardingFormData } from '../types';
import { updateOnboarding } from '../../../../api/api';
import { formDataToAPIData } from '../data';

interface AutoPersistFormDataProps {
  onboarding: OnboardingData;
}

export const AutoPersistFormData: React.FC<AutoPersistFormDataProps> = ({ onboarding }) => {
  const form = useFormikContext<OnboardingFormData>();

  const [debouncedValues] = useDebounce(form.values, 500, { equalityFn: isEqual });

  useEffect(() => {
    updateOnboarding(formDataToAPIData(onboarding, debouncedValues));
  }, [onboarding, debouncedValues]);

  return null;
};
