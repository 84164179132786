import { UnknownBadge } from '@meterup/common';
import { BodyMono2, List, ListItemLabel, ListItemPair, ListItemValue } from '@meterup/metric';
import oui from 'oui';
import React from 'react';

import type { ClientData } from '../../api/types';

export const ClientSummaryWidget = ({ client }: { client: ClientData }) => {
  const manufacturer = oui(client.mac_address) ?? <UnknownBadge />;
  return (
    <List>
      <ListItemPair>
        <ListItemLabel>Hostname</ListItemLabel>
        <ListItemValue>{client.name}</ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Manufacturer</ListItemLabel>
        <ListItemValue>{manufacturer}</ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>MAC</ListItemLabel>
        <ListItemValue>
          <BodyMono2>{client.mac_address}</BodyMono2>
        </ListItemValue>
      </ListItemPair>
    </List>
  );
};
