import type { FormikErrors } from 'formik';
import * as z from 'zod';

import type { OnboardingFormData } from './types';
import { validSSID } from '../../../validations/validSSID';
import { validWifiPSK } from '../../../validations/validWifiPSK';

type FormikValidator<Values> = (values: Values) => void | object | Promise<FormikErrors<Values>>;

export const validate: FormikValidator<OnboardingFormData> = (values) => {
  const errors: FormikErrors<OnboardingFormData> = {};

  if (values.mainNetworkData.ssid === values.guestNetworkData.ssid) {
    errors.guestNetworkData = {};
    errors.guestNetworkData.ssid = 'Your guest SSID cannot be the same as your corporate SSID';
  }

  return errors;
};

export const validOnboardingFormData = z.object({
  mainNetworkData: z.object({
    ssid: validSSID,
    password: validWifiPSK,
  }),
  guestNetworkData: z.object({
    ssid: validSSID,
    password: validWifiPSK,
  }),
});
