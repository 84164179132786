import type { HTMLProps } from 'react';
import cx from 'classnames';
import React from 'react';

import Icon from '../../assets/Icon/Icon';

export interface ServiceButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size' | 'type'> {
  label: string;
  icon?: React.ReactNode;
  arrangement?: 'icon-only' | 'icon-and-text';
  size?: 'large' | 'extra-large';
}

export const ServiceButton: React.FunctionComponent<ServiceButtonProps> = ({
  /**
   * Set the content's order and visibilty.
   */
  arrangement = 'icon-and-text',
  /**
   * Set an HTML classname.
   */
  className,
  /**
   * Display the label of the component. **Required** for accessibility support.
   */
  label,
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon,
  /**
   * Set a size of the component.
   */
  size = 'large',
  ...props
}) => {
  const classes = cx(
    className,

    // Base
    'm-flex m-group m-focus m-cursor-pointer m-rounded-lg m-w-full m-flex m-items-center m-gap-x-3',

    {
      'm-h-9 m-px-3': size === 'large',
      'm-h-12 m-px-4': size === 'extra-large',
    },

    // Text
    'm-text-base m-leading-6',

    // Initial, Light
    'm-text-gray-700 m-shadow-controlTertiaryInitialLight',

    // Hover, Light
    'hover:m-text-gray-800 hover:m-shadow-controlTertiaryHoveredLight',

    // Initial, Dark
    'dark:m-text-gray-50 dark:m-shadow-controlTertiaryInitialDark',

    // Hover, Dark
    'dark:hover:m-text-white dark:hover:m-shadow-controlTertiaryHoveredDark',
  );

  const arrowClasses = cx(
    // Base
    'm-ml-auto m-text-xs',

    // Initial, Light
    'm-text-gray-600',

    // Hover, Light
    'group-hover:m-text-group-700',

    // Initial, Dark
    'dark:m-text-gray-100',

    // Hover, Dark
    'dark:group-hover:m-text-gray-50',

    'm-transition-all m-duration-150 m-ease-linear',
  );

  return (
    <button {...props} className={classes} type="button" aria-label={label}>
      {icon}
      {arrangement === 'icon-and-text' ? label : undefined}
      <Icon icon="arrowRight" className={arrowClasses} />
    </button>
  );
};

export default ServiceButton;
