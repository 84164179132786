import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { Suspense, useEffect, useState } from 'react';
import { ModalProvider, OverlayProvider } from 'react-aria';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, Routes } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { instanceOf, match } from 'ts-pattern';
import pageRoutes from 'virtual:pagefiles/pages';

import { NotFoundError } from '../errors';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { useValidateFeatureFlags } from '../hooks/useValidateFeatureFlags';
import { ViewportProvider } from '../hooks/useViewport';
import { Nav } from '../nav';
import { CurrentCompanyProvider } from '../providers/CurrentCompanyProvider';
import {
  CurrentControllerOrErrorProvider,
  CurrentControllerProvider,
} from '../providers/CurrentControllerProvider';
import { IdentityDataProvider } from '../providers/IdentityDataProvider';
import { routes } from '../routes';
import { colors, globalCss, styled } from '../stitches';
import { DashboardAppLayout } from './AppLayout/DashboardAppLayout';
import { AppSkeleton } from './AppSkeleton';
import Clients from './Clients/Clients';
import Devices from './Devices/Devices';
import { networkDrawerRoutes } from './DrawerRoutes';
import { FatalErrorFallback } from './ErrorFallback/ErrorFallback';
import ListTokens from './MeterAuth/ListTokens';
import NetworkSidebarDesktop from './Navigation/NetworkSidebarDesktop';
import NetworkSidebarMobile from './Navigation/NetworkSidebarMobile';
import { ScopedMobileSidebarToggle } from './Navigation/ScopedMobileSidebar';
import { networkSidebarEntries } from './Navigation/sidebarEntries';
import { SidebarMainDrawerLayout } from './NetworkContainer/SidebarMainDrawerLayout';
import { OverlayableContainer, overlayableRootCSS } from './overlays';
import Overview from './Overview/Overview';
import InternetAndWireless from './Settings/InternetWiFi/InternetAndWireless';
import ListUsers from './Settings/Users/ListUsers';
import { NetworkSetup } from './Setup/NetworkSetup';
import { TabletJoinInstructions } from './TabletJoinInstructions';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) =>
        match(error)
          .with(instanceOf(NotFoundError), () => false)
          .otherwise(() => true),
    },
  },
});

const injectGlobalStyles = globalCss({
  'html, body, #root': {
    height: '100%',
    width: '100%',
    overscrollBehaviorY: 'none',
    position: 'fixed',
    overflow: 'hidden',
  },
  body: {
    background: colors.white,
    fontSize: 16,
    '&.m-dark': {
      background: colors['gray-800'],
    },
    // Enable improved font rendering only on high-DPI displays
    '@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)':
      {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
  },
  '#root': overlayableRootCSS,
});

const StyledOverlayProvider = styled(OverlayProvider, OverlayableContainer);

const DashboardApp = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const PagefileRoutes = Nav.useRoutes('root', pageRoutes);
  const DrawerRoutes = Nav.useRoutes('drawer', networkDrawerRoutes);

  const flags = useFeatureFlags();

  return (
    <Routes>
      {flags['visual-refresh'] && <Route path="*" element={PagefileRoutes} />}
      <Route
        path={routes.network.ssidInstructions.path}
        element={
          <CurrentControllerOrErrorProvider>
            <TabletJoinInstructions />
          </CurrentControllerOrErrorProvider>
        }
      />
      <Route element={<DashboardAppLayout />}>
        <Route
          element={
            <SidebarMainDrawerLayout
              mobileSidebarToggle={
                <ScopedMobileSidebarToggle
                  isOpen={false}
                  entries={networkSidebarEntries}
                  onClick={openMobileMenu}
                />
              }
              mobileSidebar={isMobileMenuOpen && <NetworkSidebarMobile onClose={closeMobileMenu} />}
              desktopSidebar={<NetworkSidebarDesktop />}
              main={<Outlet />}
              drawer={DrawerRoutes}
            />
          }
        >
          <Route path={routes.settings.users.list.path} element={<ListUsers />} />
          <Route path={routes.network.overview.path}>
            <Route path={routes.network.overview.path} element={<Overview />} />
            {!flags['visual-refresh'] && (
              <Route path={routes.network.clients.list.path} element={<Clients />} />
            )}
            {!flags['visual-refresh'] && (
              <Route path={routes.network.devices.list.path} element={<Devices />} />
            )}
            {!flags['visual-refresh'] && (
              <Route path={routes.network.internetWiFi.path} element={<InternetAndWireless />} />
            )}
            <Route path={routes.network.meterAuth.list.path} element={<ListTokens />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

const DashboardAppProviders = ({ children }: { children: React.ReactNode }) => (
  <CurrentControllerProvider>{children}</CurrentControllerProvider>
);

const AppProviders = ({ children }: React.PropsWithChildren<{}>) => (
  <Router>
    <Nav.Provider>
      <QueryClientProvider client={queryClient}>
        <StyledOverlayProvider>
          <ModalProvider>
            <IdentityDataProvider>
              <CurrentCompanyProvider>
                <ViewportProvider>{children}</ViewportProvider>
              </CurrentCompanyProvider>
            </IdentityDataProvider>
          </ModalProvider>
        </StyledOverlayProvider>
      </QueryClientProvider>
    </Nav.Provider>
  </Router>
);

const App: React.FC = () => {
  useEffect(() => {
    injectGlobalStyles();
  });

  useValidateFeatureFlags();

  return (
    <ErrorBoundary fallback={FatalErrorFallback}>
      <Suspense fallback={<AppSkeleton />}>
        <AppProviders>
          <Routes>
            <Route path={routes.setup.root.path} element={<NetworkSetup />} />
            <Route
              path="/*"
              element={
                <DashboardAppProviders>
                  <DashboardApp />
                </DashboardAppProviders>
              }
            />
          </Routes>
        </AppProviders>
      </Suspense>
    </ErrorBoundary>
  );
};

const withLaunchDarkly = withLDProvider({
  clientSideID: import.meta.env.LAUNCHDARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});

export default Sentry.withProfiler(withLaunchDarkly(App));
