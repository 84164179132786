import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@meterup/metric';
import { orderBy } from 'lodash';
import React from 'react';

import { useLogoutHandler } from '../hooks/useLogoutHandler';
import { useChangeCurrentCompanyFn, useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useIdentity } from '../providers/IdentityDataProvider';
import { SettingsButton } from './SettingsButton';

export const HeaderDropdownMenu = () => {
  const identity = useIdentity();

  const logout = useLogoutHandler();
  const currentCompany = useCurrentCompany();
  const changeCurrentCompany = useChangeCurrentCompanyFn();

  const sortedCompanySlugs = orderBy(identity.company_memberships, (c) => c.company_slug).map(
    (c) => c.company_slug,
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SettingsButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {identity.username.endsWith('@meter.com') && (
          <>
            <DropdownMenuLabel>Current company</DropdownMenuLabel>
            {sortedCompanySlugs.map((slug) => (
              <DropdownMenuCheckboxItem
                key={slug}
                checked={currentCompany === slug}
                onSelect={() => changeCurrentCompany(slug)}
              >
                {slug}
              </DropdownMenuCheckboxItem>
            ))}
            <DropdownMenuSeparator />
          </>
        )}

        <DropdownMenuItem icon="power" onSelect={logout}>
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
