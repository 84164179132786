import { isDefined } from '@meterup/common';
import {
  MobileSidebarToggle,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarNavItem,
} from '@meterup/metric';
import React from 'react';
import { Link } from 'react-router-dom';

import type { SidebarData } from './sidebarEntries';
import { getFeatureFlagPredicate } from '../../feature_flags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../hooks/useIsActive';
import { useActiveSidebarEntry } from './useActiveSidebarEntry';

interface ScopedMobileSidebarToggleProps {
  entries: SidebarData;
  isOpen: boolean;
  onClick: () => void;
}

export const ScopedMobileSidebarToggle = ({
  onClick,
  entries,
  isOpen,
}: ScopedMobileSidebarToggleProps) => {
  const activeSidebarEntry = useActiveSidebarEntry(entries);
  return (
    <MobileSidebarToggle
      icon={isOpen ? 'cross' : 'menu'}
      label={isDefined(activeSidebarEntry) ? activeSidebarEntry.label : ''}
      onClick={onClick}
    />
  );
};

interface ScopedMobileSidebarEntriesProps {
  entries: SidebarData;
  onClose: () => void;
}

export const ScopedMobileSidebarEntries = ({
  entries,
  onClose,
}: ScopedMobileSidebarEntriesProps) => {
  const isActiveTest = useIsActiveMatcher();
  const flags = useFeatureFlags();
  return (
    <>
      {entries.map((group, i) => (
        <SidebarGroup key={group.label ?? i}>
          {group.label && <SidebarGroupLabel>{group.label}</SidebarGroupLabel>}
          {group.entries.filter(getFeatureFlagPredicate(flags)).map((entry) => (
            <SidebarNavItem
              as={Link}
              key={entry.label}
              to={entry.path}
              onClick={onClose}
              icon={entry.icon}
              label={entry.label}
              isSelected={isActiveTest(entry.path, { exact: entry.exact })}
            />
          ))}
        </SidebarGroup>
      ))}
    </>
  );
};
