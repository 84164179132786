import { SidebarGroup, SidebarNavItem } from '@meterup/metric';
import React from 'react';

import { ReactRouterLink } from '../../../components/ReactRouterLink';
import { paths } from '../../../constants';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { Nav } from '../../../nav';
import { useCurrentControllerVersion } from '../../../providers/CurrentControllerProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

export const SidebarEntries = ({ onNavItemClick }: { onNavItemClick?: () => void }) => {
  const location = Nav.useRegionLocation('root');
  const isActiveTest = useIsActiveMatcher();
  const controllerVersion = useCurrentControllerVersion();
  const flags = useFeatureFlags();

  return (
    <>
      <SidebarGroup>
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink('/', {})}
          label="Overview"
          isSelected={location.pathname === '/'}
          icon="home"
          onClick={onNavItemClick}
        />
      </SidebarGroup>
      <SidebarGroup>
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink(paths.pages.ClientsPage, {})}
          label="Clients"
          isSelected={isActiveTest('/clients')}
          icon="client"
          onClick={onNavItemClick}
        />
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink(paths.pages.AccessPointListPage, {})}
          label="Access points"
          isSelected={isActiveTest(paths.pages.AccessPointListPage)}
          icon="device"
          onClick={onNavItemClick}
        />
      </SidebarGroup>
      <SidebarGroup>
        {controllerVersion.isCOS && flags['cos-config'] ? (
          <>
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.ISPListPage, {})}
              label="ISPs"
              isSelected={isActiveTest(paths.pages.ISPListPage)}
              icon="internet"
              onClick={onNavItemClick}
            />
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.SSIDListPage, {})}
              label="SSIDs"
              isSelected={isActiveTest(paths.pages.SSIDListPage)}
              icon="wifi"
              onClick={onNavItemClick}
            />
          </>
        ) : (
          <SidebarNavItem
            as={ReactRouterLink}
            to={makeLink(paths.pages.InternetAndWirelessPage, {})}
            label="ISPs"
            isSelected={isActiveTest(paths.pages.InternetAndWirelessPage)}
            icon="internet"
            onClick={onNavItemClick}
          />
        )}
      </SidebarGroup>
    </>
  );
};
