import { Skeleton } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';

import { useDrawerLocation } from '../../hooks/useDrawerLocation';
import { Nav } from '../../nav';
import { darkThemeSelector, shadows, styled } from '../../stitches';
import { LoadingDrawer } from '../Drawers/LoadingDrawer';
import { ErrorFallback, ErrorFallbackDrawer } from '../ErrorFallback/ErrorFallback';

const Layout = styled('div', {
  height: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content 1fr',
  gridTemplateAreas: '"menu-toggle" "main"',
  '@sm': {
    gridTemplateColumns: '260px 1fr',
    gridTemplateRows: '100%',
    gridTemplateAreas: '"sidebar main"',
  },
  variants: {
    drawerVisible: {
      true: {
        gridTemplateAreas: '"menu-toggle" "drawer"',
        '@sm': {
          gridTemplateColumns: '260px 1fr minmax(300px, 360px)',
          gridTemplateAreas: '"sidebar main drawer"',
        },
      },
      false: {},
    },
  },
});

const MainContainer = styled('div', {
  gridArea: 'main',
  overflow: 'auto',
  webkitOverflowScrolling: 'touch',
  variants: {
    drawerVisible: {
      true: {
        display: 'none',
        '@sm': {
          display: 'block',
        },
      },
      false: {},
    },
  },
});

const SidebarContainer = styled('div', {
  gridArea: 'sidebar',
  display: 'none',
  overflow: 'auto',
  webkitOverflowScrolling: 'touch',
  '@sm': {
    display: 'block',
  },
});

const DrawerContainer = styled('div', {
  gridArea: 'drawer',
  // overflow: 'auto',
  webkitOverflowScrolling: 'touch',
});

const MobileMenuToggleContainer = styled('div', {
  gridArea: 'menu-toggle',
  boxShadow: shadows.fenceBottomLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceBottomDark,
  },
  '@sm': {
    display: 'none',
  },
});

const LoadingFallbackContainer = styled('div', {
  padding: '$20',
  vStack: '$16',
  alignItems: 'start',
});

const LoadingFallback = () => (
  <LoadingFallbackContainer>
    <Skeleton width="200px" height="28px" radius={8} />
    <Skeleton width="100%" height="240px" radius={8} />
  </LoadingFallbackContainer>
);

export interface SidebarMainDrawerLayoutProps {
  mobileSidebarToggle: React.ReactNode;
  mobileSidebar?: React.ReactNode;
  desktopSidebar: React.ReactNode;
  main: React.ReactNode;
  drawer?: React.ReactNode;
}

export const SidebarMainDrawerLayout = ({
  mobileSidebar,
  mobileSidebarToggle,
  desktopSidebar,
  main,
  drawer,
}: SidebarMainDrawerLayoutProps) => {
  const drawerVisible = !!drawer;
  const location = Nav.useRegionLocation('root');
  const drawerLocation = useDrawerLocation();

  return (
    <Layout drawerVisible={drawerVisible}>
      <MobileMenuToggleContainer>{mobileSidebarToggle}</MobileMenuToggleContainer>
      {mobileSidebar}
      <SidebarContainer>{desktopSidebar}</SidebarContainer>
      <MainContainer drawerVisible={drawerVisible}>
        <ErrorBoundary
          // TRICKY: Pass key to the error boundary to force re-render when route changes
          key={location.pathname}
          fallback={ErrorFallback}
        >
          <Suspense fallback={<LoadingFallback />}>{main}</Suspense>
        </ErrorBoundary>
      </MainContainer>
      {drawerVisible && (
        <DrawerContainer>
          <ErrorBoundary key={drawerLocation?.pathname ?? ''} fallback={ErrorFallbackDrawer}>
            <Suspense fallback={<LoadingDrawer />}>{drawer}</Suspense>
          </ErrorBoundary>
        </DrawerContainer>
      )}
    </Layout>
  );
};
