import {
  Badge,
  List,
  ListItemLabel,
  ListItemNavigationArrow,
  ListItemPair,
  ListItemValue,
} from '@meterup/metric';
import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from '../../routes';

export const StatusWidget = (props: { numClients: number; numDevices: number }) => (
  <List>
    <ListItemPair as={Link} to={routes.network.clients.list.path}>
      <ListItemLabel>Clients</ListItemLabel>
      <ListItemValue>
        <Badge variant={props.numClients > 0 ? 'positive' : 'neutral'}>{props.numClients}</Badge>
      </ListItemValue>
      <ListItemNavigationArrow />
    </ListItemPair>
    <ListItemPair as={Link} to={routes.network.devices.list.path}>
      <ListItemLabel>Devices</ListItemLabel>
      <ListItemValue>
        <Badge variant={props.numDevices > 0 ? 'positive' : 'neutral'}>{props.numDevices}</Badge>
      </ListItemValue>
      <ListItemNavigationArrow />
    </ListItemPair>
  </List>
);
