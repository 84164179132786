import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { AccessPointDetailDrawer } from '../../../components/AccessPoints/AccessPointDetail';

export const Meta: PagefileMetaFn = () => ({
  path: '/devices/:deviceName',
});

export default function AccessPointDetail() {
  return <AccessPointDetailDrawer />;
}
