import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidServiceSetData } from '../../../../validations/validServiceSetData';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../hooks/useControllerConfig';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { validServiceSetData } from '../../../../validations/validServiceSetData';
import { modelToFormData } from './form_data';
import { useUpdateServiceSetMutation } from './hooks';
import { BandsField, PasswordField, SSIDField, VLANField } from './ssid_fields';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/ssids/:id/edit',
});

export default function SSIDEditPage() {
  const { id } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.SSIDEditPage));
  const controller = useCurrentController();

  const configModel = useControllerConfig(controller);

  expectDefinedOrThrow(
    configModel,
    new ResourceNotFoundError(`Controller ${controller} not found`),
  );

  const serviceSetModel = configModel.getServiceSetByStableId(id);

  expectDefinedOrThrow(serviceSetModel);

  const closeDrawer = useCloseDrawerCallback();

  const navigate = useNavigate();

  const updateConfigMutation = useUpdateServiceSetMutation(
    controller,
    configModel,
    serviceSetModel,
  );

  return (
    <Formik<ValidServiceSetData>
      validate={withZodSchema(validServiceSetData)}
      initialValues={modelToFormData(serviceSetModel)}
      onSubmit={(values) => updateConfigMutation.mutate(values)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Configure SSID</DrawerTitle>
            <DrawerControls>
              <DropdownMenu>
                <DropdownMenuTriggerButton
                  variant="secondary"
                  icon="overflowVertical"
                  arrangement="hidden-label"
                >
                  Actions
                </DropdownMenuTriggerButton>

                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={() =>
                      navigate(
                        makeDrawerLink(window.location, paths.drawers.SSIDRemovePage, { id }),
                      )
                    }
                  >
                    Remove SSID
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <CloseDrawerButton />
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <SSIDField />
            <PasswordField />
            <BandsField />
            <VLANField model={configModel} />
          </DrawerContent>
          <DrawerFooter>
            <DrawerControls>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DrawerControls>
          </DrawerFooter>
        </Drawer>
      </StyledForm>
    </Formik>
  );
}
