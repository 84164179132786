import { Body, HStack, Link, space } from '@meterup/metric';
import React from 'react';

export const Footer: React.FC = () => (
  <HStack justify="between" spacing={space(12)}>
    <Body color={{ light: 'gray-500', dark: 'gray-300' }}>
      Meter, Inc. • <Link href="https://www.meter.com/support">Support & FAQs</Link>
    </Body>
  </HStack>
);
