import { Header, HeaderLogo, HeaderNavItem } from '@meterup/metric';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getFeatureFlagPredicate } from '../../feature_flags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { HeaderDropdownMenu } from '../HeaderDropdownMenu';
import { appNavigationEntries, getActiveKey } from './appNavigationEntries';

const DesktopAppNavigation = () => {
  const location = useLocation();

  const activeKey = getActiveKey(location.pathname);
  const flags = useFeatureFlags();

  return (
    <Header
      navigation={
        <>
          {appNavigationEntries.filter(getFeatureFlagPredicate(flags)).map((entry) => (
            <HeaderNavItem
              as={Link}
              to={entry.path}
              label={entry.label}
              key={entry.key}
              isSelected={entry.key === activeKey}
              icon={entry.icon}
            />
          ))}
        </>
      }
      logo={<HeaderLogo />}
      controls={<HeaderDropdownMenu />}
    />
  );
};

export default DesktopAppNavigation;
