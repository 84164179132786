import { isDefined, isDefinedAndNotEmpty, RotationStrategyBadge } from '@meterup/common';
import { ListItemLabel, ListItemPair, ListItemValue } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchControllerSSIDs } from '../../../api/api';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { getWiFiQRCodeConnectionString } from '../../../utils/getWiFiQRCodeConnectionString';
import { CollapsibleList } from '../../CollapsibleList';
import { DrawerContentStack } from './DrawerContentStack';
import { PasswordViewerListItem } from './PasswordViewerListItem';
import { QRCodeListItem } from './QRCodeListItem';

export const NonMeterAuthNetworkInfo = () => {
  const controller = useCurrentController();

  const { data: networkInfo } = useQuery(
    ['controller', controller, 'ssids'],
    () => fetchControllerSSIDs(controller),
    {
      suspense: true,
      retry: false,
    },
  );

  return isDefined(networkInfo) ? (
    <DrawerContentStack>
      {networkInfo.map((ssid) => (
        <CollapsibleList
          key={ssid.ssid}
          title={ssid.ssid}
          defaultOpen={ssid.ssid.toLowerCase().includes('guest')}
        >
          <QRCodeListItem
            text={getWiFiQRCodeConnectionString(ssid.ssid, ssid.password?.value ?? '')}
          />
          <ListItemPair>
            <ListItemLabel>SSID</ListItemLabel>
            <ListItemValue>{ssid.ssid}</ListItemValue>
          </ListItemPair>
          {isDefinedAndNotEmpty(ssid.password) && (
            <PasswordViewerListItem label="Password" password={ssid.password.value} />
          )}
          {ssid.password?.type === 'rotating' && ssid.password.rotation_interval_name && (
            <ListItemPair>
              <ListItemLabel>Password rotation</ListItemLabel>
              <ListItemValue>
                <RotationStrategyBadge value={ssid.password.rotation_interval_name} />
              </ListItemValue>
            </ListItemPair>
          )}
        </CollapsibleList>
      ))}
    </DrawerContentStack>
  ) : null;
};
