import React from 'react';
import { Body, space, Spinner, VStack } from '@meterup/metric';

const Loading = () => (
  <div
    style={{
      alignItems: 'center',
      height: 'calc(100vh - 200px)',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <VStack align="center" justify="center" spacing={space(16)}>
      <Spinner />
      <Body color={{ light: 'gray-600', dark: 'gray-200' }}>Loading...</Body>
    </VStack>
  </div>
);

export default Loading;
