import type { Location } from 'history';
import type { PathMatch } from 'react-router';
import { useLocation } from 'react-router';

import type { PathMatchOptions, To } from '../utils/locations';
import { matchPathFromLocation } from '../utils/locations';

export interface IsActiveOptions extends PathMatchOptions {
  /**
   * A function to add extra logic for determining whether the link is active.
   */
  isActive?: (match: PathMatch | null, location: Location) => boolean;
}

/**
 * A hook that returns a function to determine whether a link is active.
 */
export const useIsActiveMatcher = () => {
  const currentLocation = useLocation();
  return (to: To<unknown>, options: IsActiveOptions = {}) =>
    !!matchPathFromLocation(to, currentLocation, options);
};
