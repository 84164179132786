import type { Column } from 'react-table';
import { Button } from '@meterup/metric';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import type { ClientData } from '../../api/types';
import type { TabFilter } from '../Table/TabFilters';
import { fetchClients } from '../../api/api';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useDrawerParams } from '../../hooks/useDrawerParams';
import { Nav } from '../../nav';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import {
  FilterStrategy,
  getActiveFilter,
  getClientConnectionStatus,
  isGuest,
  isWired,
  isWireless,
} from '../../utils/clientLists';
import { Page, PageControls, PageHeader, PageSection, PageTitle } from '../Page/Page';
import { TabFilters } from '../Table/TabFilters';
import { Table } from '../Table/Table';
import { CellConnectionStatus } from '../Table/tableCells';

const Clients = () => {
  const controller = useCurrentController();
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = useDrawerParams(routes.drawers.clients.detail.path);

  const clients = useQuery(['clients', controller], () => fetchClients(controller), {
    suspense: true,
  });

  const [filterStrategy, setFilterStrategy] = useState<FilterStrategy>(FilterStrategy.All);

  const allClients = clients.data ?? [];

  const columns = useMemo(
    (): Column<ClientData>[] => [
      {
        Header: 'Name',
        accessor: (row) => row.name,
      },
      {
        Header: 'Status',
        accessor: (row) => getClientConnectionStatus(row),
        Cell: CellConnectionStatus,
      },
      {
        Header: 'Location',
        accessor: (row) => (isWired(row) ? 'Wired' : row.ap_location),
      },
      {
        Header: 'MAC',
        accessor: (row) => row.mac_address,
      },
      {
        Header: 'IP',
        accessor: (row) => row.ip_address,
      },
    ],
    [],
  );

  const filters: TabFilter<FilterStrategy>[] = [
    {
      key: FilterStrategy.All,
      label: 'All',
      count: allClients?.length,
    },
    {
      key: FilterStrategy.Wired,
      label: 'Wired',
      count: allClients?.filter(isWired).length,
    },
    {
      key: FilterStrategy.Wireless,
      label: 'Wireless',
      count: allClients?.filter(isWireless).length,
    },
    {
      key: FilterStrategy.Guest,
      label: 'Guest',
      count: allClients?.filter(isGuest).length,
    },
  ];

  const filter = getActiveFilter(filterStrategy);
  const filteredClients = allClients?.filter(filter);

  return (
    <Page>
      <PageSection>
        <PageHeader>
          <PageTitle>Clients</PageTitle>
          <PageControls>
            <Button
              variant="secondary"
              as={Nav.Link}
              to={{ drawer: routes.drawers.clients.add.path }}
            >
              Show wireless info
            </Button>
          </PageControls>
        </PageHeader>
        <Table
          columns={columns}
          data={filteredClients}
          tabs={
            <TabFilters
              filters={filters}
              activeFilterKey={filterStrategy}
              onActivateFilter={({ key }) => setFilterStrategy(key)}
            />
          }
          linkProps={(client) => ({
            to: {
              drawer: routes.drawers.clients.detail.pathTo(client.mac_address),
            },
          })}
          onRowDeselect={closeDrawer}
          isRowSelected={(client) => client.mac_address === drawerParams?.macAddress}
          emptyStateHeading="No clients"
        />
      </PageSection>
    </Page>
  );
};

export default Clients;
