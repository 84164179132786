import {
  BodyMono2,
  List,
  ListItem,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  ListTitle,
} from '@meterup/metric';
import React from 'react';

import type { ClientData } from '../../api/types';
import { isLastSeenKnown, isWireless } from '../../utils/clientLists';
import { CellClientLastSeenTime } from '../Table/tableCells';

export const ConnectionDetailsWidget = ({ client }: { client: ClientData }) => {
  const ssids = client.ssids ?? [];
  return (
    <List>
      <ListItem>
        <ListTitle>Connection</ListTitle>
      </ListItem>
      {ssids.length > 0 && ssids[0] !== '' && (
        <ListItemPair>
          <ListItemLabel>SSID{ssids.length > 1 ? 's' : ''}</ListItemLabel>
          <ListItemValue>
            <BodyMono2>{ssids.join(', ')}</BodyMono2>
          </ListItemValue>
        </ListItemPair>
      )}
      <ListItemPair>
        <ListItemLabel>IP</ListItemLabel>
        <ListItemValue>
          <BodyMono2>{client.ip_address}</BodyMono2>
        </ListItemValue>
      </ListItemPair>
      {isLastSeenKnown(client) && (
        <ListItemPair>
          <ListItemLabel>Last seen</ListItemLabel>
          <ListItemValue>
            <CellClientLastSeenTime value={client.last_seen} />
          </ListItemValue>
        </ListItemPair>
      )}
      {isWireless(client) && (
        <>
          <ListItemPair>
            <ListItemLabel>Signal</ListItemLabel>
            <ListItemValue as={BodyMono2}>{client.signal}</ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Noise</ListItemLabel>
            <ListItemValue as={BodyMono2}>{client.noise}</ListItemValue>
          </ListItemPair>
          <ListItemPair>
            <ListItemLabel>Channel</ListItemLabel>
            <ListItemValue as={BodyMono2}>{client.channel}</ListItemValue>
          </ListItemPair>
        </>
      )}
    </List>
  );
};
