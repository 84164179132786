import { Tab, Tabs } from '@meterup/metric';
import React from 'react';

export interface TabFilter<Key extends React.Key = React.Key> {
  key: Key;
  label: React.ReactNode;
  count?: number;
}

export interface TabFiltersProps<Key extends React.Key = React.Key> {
  filters: TabFilter<Key>[];
  activeFilterKey: Key;
  onActivateFilter: (filter: TabFilter<Key>) => void;
}

export const TabFilters = <Key extends React.Key = React.Key>({
  filters,
  activeFilterKey,
  onActivateFilter,
}: TabFiltersProps<Key>) => (
  <Tabs>
    {filters.map((filter) => (
      <Tab
        key={filter.key}
        annotation={filter.count}
        active={filter.key === activeFilterKey}
        onClick={() => onActivateFilter(filter)}
      >
        {filter.label}
      </Tab>
    ))}
  </Tabs>
);
