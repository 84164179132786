import { SidebarGroup, SidebarGroupLabel, SidebarNavItem } from '@meterup/metric';
import React from 'react';
import { Link } from 'react-router-dom';

import type { SidebarData } from './sidebarEntries';
import { getFeatureFlagPredicate } from '../../feature_flags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../hooks/useIsActive';

interface ScopedDesktopSidebarEntriesProps {
  entries: SidebarData;
}

export const ScopedDesktopSidebarEntries = ({ entries }: ScopedDesktopSidebarEntriesProps) => {
  const isActiveTest = useIsActiveMatcher();
  const flags = useFeatureFlags();
  return (
    <>
      {entries.map((group, i) => (
        <SidebarGroup key={group.label ?? i}>
          {group.label && <SidebarGroupLabel>{group.label}</SidebarGroupLabel>}
          {group.entries.filter(getFeatureFlagPredicate(flags)).map((entry) => (
            <SidebarNavItem
              key={entry.label}
              as={Link}
              to={entry.path}
              icon={entry.icon}
              label={entry.label}
              isSelected={isActiveTest(entry.path, { exact: entry.exact })}
            />
          ))}
        </SidebarGroup>
      ))}
    </>
  );
};
