import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  DesktopSidebar,
  MobileSidebar,
  MobileSidebarLocationControlContainer,
  MobileSidebarToggle,
  MobileSidebarToggleContainer,
  SidebarGroup,
  SidebarNavItem,
} from '@meterup/metric';
import React, { useState } from 'react';
import { Outlet } from 'react-router';

import { DrawerRoutes } from '../../../components/DrawerRoutes';
import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';
import { LocationControl } from '../../../components/Navigation/LocationControl';
import { MobileSidebarModal } from '../../../components/Navigation/MobileSidebarModal';
import { SidebarAndMainLayout } from '../../../components/SidebarAndMainLayout';
import { useLogoutHandler } from '../../../hooks/useLogoutHandler';
import { styled } from '../../../stitches';
import { SidebarEntries } from './SidebarEntries';

export const Meta: PagefileMetaFn = () => ({
  path: '/',
  layout: 'AppLayout',
});

const LogoutButton = styled('div', { marginTop: 'auto', width: '100%', padding: '$12' });

const NetworkSidebarDesktop = () => (
  <DesktopSidebar>
    <SidebarEntries />
    <LogoutButton>
      <SidebarNavItem as="button" label="Log out" onClick={useLogoutHandler()} />
    </LogoutButton>
  </DesktopSidebar>
);

const NetworkSidebarMobile = ({ onClose }: { onClose: () => void }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <MobileSidebarToggle icon="cross" label="Navigation" onClick={onClose} />
      </MobileSidebarToggleContainer>
      <SidebarEntries onNavItemClick={onClose} />
      <SidebarGroup>
        <SidebarNavItem as="button" label="Log out" onClick={useLogoutHandler()} />
      </SidebarGroup>
      <MobileSidebarLocationControlContainer>
        <LocationControl onClose={onClose} />
      </MobileSidebarLocationControlContainer>
    </MobileSidebar>
  </MobileSidebarModal>
);

export default function NetworkLayout() {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const openMobileMenu = () => setIsMobileSidebarOpen(true);
  const closeMobileMenu = () => setIsMobileSidebarOpen(false);

  return (
    <SidebarAndMainLayout
      mobileSidebarToggle={
        <MobileSidebarToggle icon="menu" label="Meter" onClick={openMobileMenu} />
      }
      mobileSidebar={
        isMobileSidebarOpen ? <NetworkSidebarMobile onClose={closeMobileMenu} /> : null
      }
      desktopSidebar={<NetworkSidebarDesktop />}
      main={<MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />}
    />
  );
}
