import type { MeterV2WirelessAccessPoint, MeterV2WirelessServiceSet } from '@meterup/config';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { Badge, Button } from '@meterup/metric';

import { Page, PageControls, PageHeader, PageTitle } from '../../../../components/Page/Page';
import { ReactRouterLink } from '../../../../components/ReactRouterLink';
import { AutoTable2 } from '../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../hooks/useControllerConfig';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';

const Box = styled('div');

interface ServiceSetAndAPs {
  serviceSet: MeterV2WirelessServiceSet;
  aps: MeterV2WirelessAccessPoint[];
  isEveryAPBroadcastingServiceSet: boolean;
}

const builder = createColumnBuilder<ServiceSetAndAPs>();

const columns = [
  builder.display({
    meta: {
      sizingMode: 'fit-min',
    },
    cell: (d) => (
      <Badge
        arrangement="hidden-label"
        icon={d.row.serviceSet.hidden ? 'eyeClosed' : 'eyeOpen'}
        variant={d.row.serviceSet.hidden ? 'neutral' : 'positive'}
        size="small"
        ends="pill"
      >
        {d.row.serviceSet.hidden ? 'SSID hidden' : 'SSID visible'}
      </Badge>
    ),
  }),
  builder.data((d) => d.serviceSet.ssid, {
    header: 'SSID',
    meta: {
      isLeading: true,
    },
  }),
  builder.display({
    header: 'Standard',
    cell: () => (
      <Badge size="small" ends="card">
        WPA2
      </Badge>
    ),
  }),
  builder.data((d) => Array.from(d.serviceSet.getSortedBands()).join(', '), {
    header: 'Bands (GHz)',
    cell: (d) => (
      <Box css={{ hStack: '$4' }}>
        {d.row.serviceSet
          .getSortedBands()
          .map((b) => b.replace(' GHz', ''))
          .map((b) => (
            <Badge key={b} size="small" ends="card">
              {b}
            </Badge>
          ))}
      </Box>
    ),
  }),
  builder.data(
    (d) => (d.isEveryAPBroadcastingServiceSet ? 'All' : d.aps.map((v) => v.name).join(', ')),
    {
      header: 'Access points',
      cell: (d) =>
        d.row.isEveryAPBroadcastingServiceSet ? (
          <Badge size="small" ends="card">
            All
          </Badge>
        ) : (
          <Box css={{ hStack: '$4' }}>
            {d.row.aps.map((b) => (
              <Badge key={b.name} size="small" ends="card">
                {b.name}
              </Badge>
            ))}
          </Box>
        ),
    },
  ),
];

export const Meta = () => ({
  path: '/ssids',
});

export default function SSIDListPage() {
  const controller = useCurrentController();
  const model = useControllerConfig(controller);

  expectDefinedOrThrow(
    model,
    new ResourceNotFoundError(`Configuration for controller ${controller} not found`),
  );

  const params = Nav.useRegionParams('drawer', paths.drawers.SSIDEditPage);
  const onRowDeselect = useCloseDrawerCallback();

  return (
    <Page css={{ gap: 0 }}>
      <PageHeader>
        <PageTitle>SSIDs</PageTitle>
        <PageControls>
          <Button
            as={ReactRouterLink}
            to={makeDrawerLink(window.location, paths.drawers.SSIDCreatePage, {})}
            variant="tertiary"
            icon="plusCircle"
            arrangement="leading-icon"
          >
            Create SSID
          </Button>
        </PageControls>
      </PageHeader>
      <AutoTable2
        data={model.serviceSets.map((serviceSet) => {
          const aps = model.getAPsBroadcastingServiceSet(serviceSet);
          return {
            serviceSet,
            aps,
            isEveryAPBroadcastingServiceSet: model.accessPoints.length === aps.length,
          };
        })}
        columns={columns}
        onRowDeselect={onRowDeselect}
        isRowSelected={(d) => d.serviceSet.stableId === params?.id}
        getLinkTo={(d) =>
          makeDrawerLink(window.location, paths.drawers.SSIDEditPage, {
            id: d.serviceSet.stableId,
          })
        }
      />
    </Page>
  );
}
