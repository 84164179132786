import type { MeterControllerConfig, MeterV2WirelessServiceSet } from '@meterup/config';
import produce from 'immer';
import { isEqual } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import type { ValidServiceSetData } from '../../../../validations/validServiceSetData';
import { upsertControllerConfigKey } from '../../../../api/api';
import { formDataToModel } from './form_data';

function saveConfigDiff(
  controllerName: string,
  modified: Record<string, any>,
  original: Record<string, any>,
) {
  const deletedKeys = Object.keys(original).filter((key) => !(key in modified));

  const modifiedKeys = Object.keys(modified).filter(
    (key) => !(key in original) || !isEqual(original[key], modified[key]),
  );

  const modifiedPromises = modifiedKeys.map((key) =>
    upsertControllerConfigKey(controllerName, key, modified[key]),
  );

  const deletedPromises = deletedKeys.map((key) =>
    upsertControllerConfigKey(controllerName, key, {}),
  );

  return Promise.all([...modifiedPromises, ...deletedPromises]);
}

export function useUpdateServiceSetMutation(
  controller: string,
  configModel: MeterControllerConfig,
  serviceSetModel: MeterV2WirelessServiceSet,
  onSuccess?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async (values: ValidServiceSetData) => {
      const ssid = formDataToModel(serviceSetModel.tagName, serviceSetModel.stableId, values);
      const updatedModel = produce(configModel, (draft) => {
        draft.upsertServiceSet(ssid);
      });

      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['controller', controller, 'config']);
        onSuccess?.();
      },
    },
  );
}

export function useRemoveServiceSetMutation(
  controller: string,
  configModel: MeterControllerConfig,
  serviceSetId: string,
  onSuccess?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const updatedModel = produce(configModel, (draft) => {
        draft.removeServiceSetById(serviceSetId);
      });

      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['controller', controller, 'config']);
        onSuccess?.();
      },
    },
  );
}
