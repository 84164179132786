import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { MeterV2WirelessServiceSet } from '@meterup/config';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidServiceSetData } from '../../../../validations/validServiceSetData';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../hooks/useControllerConfig';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { validServiceSetData } from '../../../../validations/validServiceSetData';
import { modelToFormData } from './form_data';
import { useUpdateServiceSetMutation } from './hooks';
import { BandsField, PasswordField, SSIDField, VLANField } from './ssid_fields';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/ssids/create',
});

export default function SSIDCreatePage() {
  const controller = useCurrentController();

  const model = useControllerConfig(controller);

  expectDefinedOrThrow(model, new ResourceNotFoundError(`Controller ${controller} not found`));

  const closeDrawer = useCloseDrawerCallback();

  const draftModel = useMemo(() => MeterV2WirelessServiceSet.createEmpty(), []);

  const navigate = useNavigate();

  const updateConfigMutation = useUpdateServiceSetMutation(controller, model, draftModel, () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.SSIDEditPage, { id: draftModel.stableId }),
    );
  });

  return (
    <Formik<ValidServiceSetData>
      validate={withZodSchema(validServiceSetData)}
      initialValues={modelToFormData(draftModel)}
      onSubmit={(values) => updateConfigMutation.mutate(values)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Create SSID</DrawerTitle>
            <DrawerControls>
              <CloseDrawerButton />
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <SSIDField />
            <PasswordField />
            <BandsField />
            <VLANField model={model} />
          </DrawerContent>
          <DrawerFooter>
            <DrawerControls>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DrawerControls>
          </DrawerFooter>
        </Drawer>
      </StyledForm>
    </Formik>
  );
}
