import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/pages/App.layout.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/pages/devices/AccessPointList.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/pages/network/Network.layout.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/Clients.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/ISPList.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/InternetAndWireless.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/SSIDList.page.tsx";

const routes = [
{children: [{path: "/",children: [{path: "/clients",element: React.createElement(PagefilesImport3)},
{path: "/devices",element: React.createElement(PagefilesImport1)},
{path: "/internet-wifi",element: React.createElement(PagefilesImport5)},
{path: "/isps",element: React.createElement(PagefilesImport4)},
{path: "/ssids",element: React.createElement(PagefilesImport6)},],element: React.createElement(PagefilesImport2)},],element: React.createElement(PagefilesImport0)},
];

export default routes;