import type { CellProps, Column } from 'react-table';
import { isDefinedAndNotEmpty, ISPStatusBadge } from '@meterup/common';
import { Badge, Body2, Small2 } from '@meterup/metric';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import type { SSIDData } from '../../../api/types';
import { fetchControllerSSIDs, fetchNetworkISPInfo, fetchProviders } from '../../../api/api';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useDrawerParams } from '../../../hooks/useDrawerParams';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { routes } from '../../../routes';
import { colors, fontWeights, shadows, styled } from '../../../stitches';
import { Page, PageHeader, PageSection, PageTitle } from '../../Page/Page';
import { Table } from '../../Table/Table';
import { UploadDownloadStat } from './UploadDownloadStat';

const CellNeutralSmallBadge = ({ value }: CellProps<SSIDData, string>) => (
  <Badge size="small">{value}</Badge>
);

const ConnectionGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(80px, 120px) 1fr minmax(140px, max-content)',
  alignItems: 'stretch',
});

const ConnectionRow = styled('div', {
  display: 'contents',
});

const ConnectionCell = styled('div', Body2, {
  display: 'flex',
  alignItems: 'center',
  padding: '$16',
  boxShadow: shadows.fenceBottomLight,
  '&:first-child': {
    paddingLeft: '$20',
  },
  '&:last-child': {
    paddingRight: '$20',
  },
});

const Stat = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
});

const PrimaryStatLabel = styled('div', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const StatLabel = styled('div', Small2);

const StatValue = styled('div', {
  hStack: 0,
});

const InternetAndWireless = () => {
  const controller = useCurrentController();
  const drawer = useDrawerParams(routes.drawers.ssid.detail.path);
  const closeDrawer = useCloseDrawerCallback();

  const providers =
    useQuery('providers', fetchProviders, {
      suspense: true,
    }).data ?? [];

  const connections =
    useQuery(['network_isp_info', controller], () => fetchNetworkISPInfo(controller), {
      suspense: true,
    }).data ?? [];

  const networkInfo =
    useQuery(['controller_ssids', controller], () => fetchControllerSSIDs(controller), {
      suspense: true,
    }).data ?? [];

  const columns = useMemo(
    (): Column<SSIDData>[] => [
      {
        Header: 'SSID',
        accessor: (row) => row.ssid,
      },
      {
        Header: 'Type',
        accessor: (row) => row.type,
        Cell: CellNeutralSmallBadge,
      },
    ],
    [],
  );

  return (
    <Page>
      {connections.length > 0 && (
        <PageSection>
          <PageHeader>
            <PageTitle>Internet connections</PageTitle>
          </PageHeader>
          <ConnectionGrid>
            {connections.map((plan) => {
              const path = providers.find((p) => p.sid === plan.provider)?.path;
              return (
                <ConnectionRow key={plan.sid}>
                  <ConnectionCell>
                    {isDefinedAndNotEmpty(path) && (
                      <img src={`//public.meter.com${path}`} alt={plan.provider} />
                    )}
                  </ConnectionCell>
                  <ConnectionCell>
                    <Stat>
                      <PrimaryStatLabel>{plan.provider_name}</PrimaryStatLabel>
                      <StatValue>
                        <ISPStatusBadge status={plan.status} />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                  <ConnectionCell>
                    <Stat>
                      <StatLabel>Bandwidth</StatLabel>
                      <StatValue>
                        <UploadDownloadStat
                          downloadKbps={plan.download_kbps}
                          uploadKbps={plan.upload_kbps}
                        />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                </ConnectionRow>
              );
            })}
          </ConnectionGrid>
        </PageSection>
      )}
      <PageSection>
        <PageHeader>
          <PageTitle>SSIDs</PageTitle>
        </PageHeader>
        <Table
          columns={columns}
          data={networkInfo}
          isRowSelected={(row) => row.sid === drawer?.ssidName}
          linkProps={(row) => ({ to: { drawer: routes.drawers.ssid.detail.pathTo(row.sid) } })}
          onRowDeselect={closeDrawer}
          shouldHideGlobalSearch
        />
      </PageSection>
    </Page>
  );
};

export default InternetAndWireless;
